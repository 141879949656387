import axios from "../config/axiosConfig";
import { events } from "../actions";
import { onRequestError, onCompleteRequest, formatRequest, callRestricatedAPI, } from "./common";
import { GENERIC_ERROR_CODE, } from "../constants";

export function getCityLists(offset, limit) {
  return axios(
    formatRequest(
      `/api/app/${'v1'}/city/all/${offset}/${limit}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function getCityDetails(cityId) {
  return axios(
    formatRequest(
      `/api/app/${'v1'}/city/${cityId}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function getTerminalLists(offset, limit) {
  return axios(
    formatRequest(
      `/api/app/${'v1'}/terminal/all/${offset}/${limit}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function getTerminalDetails(terminalId) {
  return axios(
    formatRequest(
      `/api/app/${'v1'}/terminal/${terminalId}`,
      "get",
      null,
      true,
      true
    )
  );
}

export function* fetchCitiesRecords(action) {
  try {
    const apiResponse = yield callRestricatedAPI(
      getCityLists, 
      action.offset,
      action.limit,
    );
    
    const { data = [], errors = [] } = apiResponse;
    if (data?.rows) {
      yield onCompleteRequest(
        events.routesManagement.city.FETCH_CITY_LIST_SUCCESS,
        data?.rows?.length ? data.rows : []
      );
    } else {
      const errorObject = errors.length > 0 ? errors[0] : {};
      const errorCode = errorObject.errorCode || GENERIC_ERROR_CODE;
      const errorMsgFromBE = errorObject.errorMsg || "";
      yield onRequestError(
        events.routesManagement.city.FETCH_CITY_LIST_FAILED,
        errorCode,
        errorMsgFromBE
      );
    }
  } catch (e) {
    yield onRequestError(
      events.routesManagement.city.FETCH_CITY_LIST_FAILED,
      GENERIC_ERROR_CODE,
      e.message
    );
  }
}

export function getRouteLists(offset, limit) {
  return axios(
    formatRequest(
      `/api/app/${'v1'}/routes/all/${offset}/${limit}`,
      "get",
      null,
      true,
      true
    )
  );
}

export function getRouteDetails(routeId) {
  return axios(
    formatRequest(
      `/api/app/${'v1'}/routes/${routeId}`,
      "get",
      null,
      true,
      true
    )
  );
}

export function fetchRouteByCities(startCity, endCity) {
  return axios(
    formatRequest(
      `/api/app/${'v1'}/routes/${startCity}/${endCity}/by-cities`,
      "get",
      null,
      true,
      true
    )
  );
}

export function getSubRouteList(routeId) {
  return axios(
    formatRequest(
      `/api/app/${'v1'}/routes/sub-route/route/${routeId}`,
      "get",
      null,
      true,
      true
    )
  );
}

