import React from "react";
import Footer from "../components/footers/Footer";
import CustomNavbar from "../components/navbars/CustomNavbar";
import { connect } from "react-redux";

class Blog extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			posts: []
		};
	}
	async componentDidMount(){
		
	}
  render() {
    return (
      <>
        <CustomNavbar />
        <section id="content" >
			<div id="content-wrap">
				<header className="blog-header">
				<div className="container">                
					<div className="search-outer">
						<div className="search-inner">
							<div className="search-text">
							<div className="search-text-1">Content is coming soon</div>
							<div className="search-text-2">We are working on bringing you rich content</div>
							</div>
						</div>
					</div>
				</div>
				</header>
			</div>
        </section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) =>{
    return {
        ...state
    }
}

export default connect(mapStateToProps, null)(Blog);
