import React from "react";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core components
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import CustomNavbar from "../components/navbars/CustomNavbar";
//Privacy item list json file 
import userAgreementList from "./userAgreementList";

class PrivacyPolicy extends React.Component {
  constructor() {
    super();
    this.state = {
      privacy: userAgreementList,
    };
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  render() {
    const userAgreementList = this.state.privacy.map((item, i) => {
      return (
        <React.Fragment key={i}>
          <h4 className="mb-4">{item.heading}</h4>
          <p className="mb-5">{item.text}</p>
        </React.Fragment>
      );
    });

    return (
      <>
        <Header />
        <CustomNavbar />
        <div id="section-privacy" className="section-flat">
          <div className="section-content">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="section-title text-center">
                    <span>
                      <em></em>
                    </span>
                    <h2 className="mt-4">
                      Share A Ride User Agreement
                    </h2>
                    <p>
                      Cum doctus civibus efficiantur in imperdiet deterruisset.
                    </p>
                  </div>
                </Col>

                <div className="clearfix"></div>

                <Col sm={12}>{userAgreementList}</Col>

                <Col sm={12}>
                  <h3 className="mb-3">CONTACT US</h3>

                  <p className="mb-3">
                    If you have any questions or comments about our Privacy
                    Policy or our company in general please contact us at
                    Sharearide@gmail.com
                  </p>

                  <h4 className="mb-3">Written inquiries can be sent to:</h4>
                  <div className="address-info">
                    <h5 className="mb-2">Share A Ride</h5>
                    <p>
                      Rua Dr. Manuel José de Oliveira Machado, 37
                      <br /> 4700-058 Braga, Portugal
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default PrivacyPolicy;
