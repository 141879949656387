const baseCountryConfig = {
  countryName: "Botswana",
  isoCode: "BW",
  countryCode: "+267",
  iconName: "botswana_flag",
  languages: [{ name: "English", locale: "en" }],
  locale: "en-BW",
  currencyCode: "BWP",
  currencySymbol: "P",
  phoneRegExp: /^[0-9]{8}$/,
  phoneNoLength: 8,
  isCountryEnabled: true,
};

export default baseCountryConfig;
