import React from "react";
import { Container, Row, Col, UncontrolledCollapse, Button, Spinner } from "reactstrap";
import { ENDPOINT } from "../constants";
import { connect } from "react-redux";
import alertify from "alertifyjs"; 
import axios from "axios";
import { redirectToRecentUrl } from "../actions/userActions";
import { recentBookingData, updateWalletBalance } from "../actions/bookingActions";
import UserSidebar from "./UserDashboardSidebar";
import { css } from "@emotion/react";
import RingLoader from "react-spinners/RingLoader";
import moment from 'moment';
import axiosConfig from "../config/axiosConfig";
import { formatRequest } from "../sagas/common";
import { countryList } from '../utils/CountryCodes.js';
import { firebaseDatabase } from "../config/firebaseConfig";
import { ref, child, get, update} from "firebase/database";

const { REACT_APP_WEB_URL,  REACT_APP_DPO_GROUP_MERCHANT_TOKEN, REACT_APP_DPO_GROUP_SERVICE_TYPE, REACT_APP_ENCRYPTION_KEY } = process.env || {};
const keyStr = REACT_APP_ENCRYPTION_KEY;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Bookings extends React.Component {

    constructor(props){
        super(props);

        this.state = {

            bookings: [],
            loading: true,
            cancelledSeats: [],
            paymentURL: '',
            bookingId: '',

            tokenData: null,
            returnPARAMSData: [],
            companyToken: "",
            serviceType: "",
            referenceText: ""
        };
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        const config = {
            dataType: "json",
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.Auth?.token,
            },
        };
        return await axios.get(`${ENDPOINT}/app/v2/bookings/${this.props.Auth?.user.userId}/${"active"}/`,config).then(async response => {
            const { data = [], success } = response?.data;
            if (success) {
                let activeBookings = data
                activeBookings = (activeBookings || []).sort((a,b) => (b.booking_date > a.booking_date) ? 1 : ((a.booking_date > b.booking_date) ? -1 : 0));
                this.setState({
                    bookings: activeBookings || [],
                });
            }
        }).catch(()=>{
            
        }).finally(()=>{
            this.setState({
                loading: false
            });
        })
    }

    updatePassengerDetails = (passenger, booking) =>{
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              "passenger":{          
                "id": passenger.id,
                "uuid": passenger.uuid,
                "names": passenger.names,
                "seatNumber": passenger.seatNumber,
                "status": passenger.status,
                "createdAt": passenger.createdAt,
                "updatedAt": passenger.updatedAt,
                "deletedAt": passenger.deletedAt,
                "bookings": passenger.bookings,
                "version": passenger.version
              }
            })
        };
      
        return fetch(`${ENDPOINT}/app/v1/transaction/bookings/${booking}/passengers/${passenger.uuid}/edit`, requestOptions);  
    }

    handleSeatRemovalEvent = async (e)=>{
        this.state.cancelledSeats.find( seat => seat.seatId === e.target.id && ( (!seat.selected ? seat.selected = true: seat.selected = false), true ) );
    }

    updateBusSeat = (reference, payload) =>{    
        try{
          update(ref(firebaseDatabase, reference), payload);
        }catch(e){
          console.log(e)
        }  
    }

    dailyRequest = (requestCount, isNew = false) =>{
        let payload = {
            requestCount: requestCount
        }
        if(isNew){
            payload ={
                ...payload,
                requestTimeStamp: moment().add(24, 'hours').unix()
            }
        }
        return payload;
    }
    weeklyRequest = (requestCount, isNew = false) =>{
        let payload = {
            requestCount: requestCount
        }
        if(isNew){
            payload ={
                ...payload,
            requestTimeStamp: moment().add(7, 'days').unix()
            }
        }
        return payload;
    }
    monthlyRequest = (requestCount, isNew = false) =>{
        let payload = {
            requestCount: requestCount
        }
        if(isNew){
            payload ={
                ...payload,
                requestTimeStamp: moment().add(28, 'days').unix()
            }
        }
        return payload;
    }
    yearlyRequest = (requestCount, isNew = false) =>{
        let payload = {
            requestCount: requestCount
        }
        if(isNew){
            payload ={
                ...payload,
                requestTimeStamp: moment().add(12, 'months').unix()
            }
        }
        return payload;
    }

    updatePolicies = (reference, payload)=>{
        update(ref(firebaseDatabase, reference), payload);
    }

    handleConfirmCancellation = async (e) =>{
        e.preventDefault();
        this.setState({
            loading: true
        });
        const passengers = this.state.cancelledSeats?.filter(seat=> seat.selected);
        const bookingId = e.target.id;
        const config = {
            dataType: "json",
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.Auth?.token,
            },
        };
        var processedRecords = 0;
        for (var i = 0; i < passengers.length; i++) {
            const tempPassenger = passengers[i];
            let passenger = tempPassenger?.passenger;
            passenger = {
                ...passenger,
                status: "cancelled"
            }
            const response = await this.updatePassengerDetails(passenger, bookingId);
            if(response.status === 200){
                /**Update firebase ticket */
                const timestamp = moment(tempPassenger?.bookingDate).format("YYYY-MM-DD").valueOf();
                const reference = "bookings/".concat(timestamp).concat("/").concat(tempPassenger?.busId).concat("/").concat(tempPassenger?.seatId);
                const payload = {
                    status: "RELEASED",
                }
                this.updateBusSeat(reference, payload);
                const policyUri = "bookings-policies/".concat(this.props.Auth?.user.userId);
                get(child(ref(firebaseDatabase), policyUri)).then((snapshot) => {
                    if (snapshot.exists()) {
                        const data = snapshot.val();
                        if(data === null){
                            /**Add new cancelled policy */
                            const policyPayload ={
                                dailyRequest: this.dailyRequest(1, true),
                                weeklyRequest: this.weeklyRequest(1, true),
                                monthlyRequest: this.monthlyRequest(1, true),
                                yearlyRequest: this.yearlyRequest(1, true)
                            }
                            this.updatePolicies(policyUri, policyPayload);
                        }else{
                            const dailyRequest = (data?.dailyRequest?.requestCount + 1) || 1;
                            const weeklyRequest = (data?.weeklyRequest?.requestCount + 1) || 1;
                            const monthlyRequest = (data?.monthlyRequest?.requestCount + 1) || 1;
                            const yearlyRequest = (data?.yearlyRequest?.requestCount + 1) || 1;
                            /**Add new cancelled policy */
                            const policyPayload ={
                                dailyRequest: this.dailyRequest(dailyRequest, true),
                                weeklyRequest: this.weeklyRequest(weeklyRequest, true),
                                monthlyRequest: this.monthlyRequest(monthlyRequest, true),
                                yearlyRequest: this.yearlyRequest(yearlyRequest, true)
                            }
                            this.updatePolicies(policyUri, policyPayload);
                        }
                    }else{
                        /**Add new cancelled policy */
                        const policyPayload ={
                            dailyRequest: this.dailyRequest(1, true),
                            weeklyRequest: this.weeklyRequest(1, true),
                            monthlyRequest: this.monthlyRequest(1, true),
                            yearlyRequest: this.yearlyRequest(1, true)
                        }
                        this.updatePolicies(policyUri, policyPayload);
                    }
                  }).catch((error) => {
                    console.error(error);
                    alertify.error("Application error when processing your booking")
                  }); 
                processedRecords++;
            }
            
            if(processedRecords === passengers.length){
                alertify.success("Seats cancelled");
                await axios.get(`${ENDPOINT}/app/v2/bookings/${this.props.Auth?.user.userId}/${"active"}/`,config).then(async response => {
                    const { data = [], success } = response?.data;
                    if (success) {
                        let activeBookings = data
                        activeBookings = (activeBookings || []).sort((a,b) => (b.booking_date > a.booking_date) ? 1 : ((a.booking_date > b.booking_date) ? -1 : 0));
                        this.setState({
                            bookings: activeBookings || [],
                        });
                    }
                })
            } else{
                alertify.error("Not all seats were cancelled");                
            }
        }
        this.setState({
            loading: false
        });        
    }

    handleCancelBooking = (booking) =>{
        const config = {
            dataType: "json",
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.Auth?.token,
            },
        };

        var node= document.getElementById('checkbox-area' + booking?.uuid);
        while (node.firstChild) {
            node.removeChild(node.firstChild);
        }

        axios.get(`${ENDPOINT}/app/v1/transaction/bookings/${booking?.uuid}/passengers`,config).then(async response => {
            const { data = [], success } = response?.data;
            if (success) {
                const passengers = data;
                if(passengers.length > 0){
                    var cancelledSeats = [];
                    for (var i = 0; i < passengers.length; i++) {
                        let outer_div = document.createElement("DIV");
                        outer_div.classList.add("form-check");
                        outer_div.classList.add("form-check-inline");
                        outer_div.style.width = "100%";

                        let checkbox = document.createElement("INPUT");
                        checkbox.setAttribute("type", "checkbox");
                        let label = document.createElement("LABEL");
                        label.classList.add("form-check-label");
                        checkbox.classList.add("form-check-input");
                        checkbox.classList.add("booked-seats-" + booking?.uuid);
                        checkbox.setAttribute("id", passengers[i].seatNumber);
                        checkbox.setAttribute("value", passengers[i].seatNumber);
                        checkbox.style.width = "24px";
                        checkbox.style.height = "24px";
                        checkbox.addEventListener("click", this.handleSeatRemovalEvent);
                        if(passengers[i].status === "used" || passengers[i].status === "cancelled"){
                            checkbox.setAttribute('disabled', 'disabled');
                            let text = document.createTextNode(passengers[i].seatNumber + " - " + passengers[i].status.charAt(0).toUpperCase() + passengers[i].status.slice(1));
                            label.appendChild(text);
                        }else{ 
                            let text = document.createTextNode(passengers[i].seatNumber);
                            label.appendChild(text);
                        }
                        outer_div.appendChild(checkbox);
                        outer_div.appendChild(label);
                        document.getElementById('checkbox-area' + booking?.uuid).appendChild(outer_div);  
                        cancelledSeats.push({
                            seatId: passengers[i].seatNumber,
                            selected: false,
                            passenger: passengers[i], 
                            bookingDate: booking?.booking_date,
                            busId: booking?.bus_id
                        }); 
                    }
                    await this.setState({
                        cancelledSeats: cancelledSeats
                    })
                    let confirm_button = document.createElement("BUTTON");
                    confirm_button.classList.add("cnfm-btn");
                    let btn_text = document.createTextNode("Confirm Cancellation");
                    confirm_button.appendChild(btn_text);
                    confirm_button.setAttribute("id", booking?.uuid);
                    confirm_button.addEventListener("click", this.handleConfirmCancellation);

                    document.getElementById('checkbox-area' + booking?.uuid).appendChild(confirm_button);
                }
            }
        });
    }

    handleWindowClose = async(bookingId) => {
        const config = {
            dataType: "json",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.props.Auth?.token,
            },
        };

        await axios.get(`${ENDPOINT}/app/v1/transaction/bookings/${bookingId}`, config).then(async (response) => {
            const { data, success } = response?.data;
            if (success) {
                let _booking = data;
                this.props.recentBookingData(_booking);
                if(_booking.isPaid === "Yes"){
                    await this.setState({
                        processing: false
                    });
                    this.props.history.push("/user/thank-you");
                }
            }else{
                await this.setState({
                    processing: false
                });
                alertify.notify("Booking process encountered partial error", 'custom', 2, function(){});
            }
        });       
    }

    handlePayBooking = async (booking) =>{
        await this.setState({
            processing: true
        });
        window.localStorage.setItem(
            "ACTIVE_BOOKING",
            JSON.stringify({
                bookingId: booking?.uuid,
                userId: this.props.Auth?.user?.userId,
            })
        );
        await this.processSubscription(booking?.bookingId);
    }

    processSubscription = async(bookingId) =>{
        try{
            const paymentInformation = {
                paymentAmount: this.props.global?.fare,
                paymentCurrency: "BWP",
                customerFirstName: this.props.Auth?.user?.firstName,
                customerLastName: this.props.Auth?.user?.lastName,
                customerAddress: "",
                customerCountry: "Botswana",
                customerCity: "",
                customerEmail: this.props.Auth?.user?.email === (null || "") ? "" : this.props.Auth?.user?.email,
                customerPhone: this.props.Auth?.user?.phone === (null || "") ? "" : this.props.Auth?.user?.phone,
                redirectURL: `${REACT_APP_WEB_URL}/payment/dpogroup/webhooks`,
                backURL: `${REACT_APP_WEB_URL}/payment/dpogroup/webhooks`,
                userId: this.props.Auth?.user?.userId
            }

            this.setState({
                tokenData: paymentInformation,
                companyToken: REACT_APP_DPO_GROUP_MERCHANT_TOKEN,
                serviceType: REACT_APP_DPO_GROUP_SERVICE_TYPE,
                referenceText: bookingId
            });

            const tokens = await this.createToken(bookingId);

            if(tokens && tokens?.transToken && tokens?.transToken !== ""){
                /**Launch DPO payment page */
                window.open(`https://secure.3gdirectpay.com/payv2.php?ID=${tokens?.transToken}`, "_self", "toolbar=yes,scrollbars=yes,resizable=no,top=300,left=500,width=768,height=600");
            }else{
                /**Error occurred generating transaction token */
                alertify.info("Booking payment failed, please try again. If the problem persist after 5 minimal attempts please reach us through our communication channel");
                await this.setState({
                    noticeModal: true
                });
            }
        } catch(err){
            console.log(err)
            alertify.error("Payment processing failed but your booking was processed");
        }
    }

    addQueryParam = (url, params ) =>{
        const urlInfo = url.split("\\?");
        let queryURL = urlInfo[0];
        let querySTR = urlInfo.length > 1 ? (urlInfo[1] !== (null && "") ? urlInfo[1] : "" ): "";
        let paramsExist = urlInfo.length > 1 && (urlInfo[1] !== (null && ""));
        if ( params.length > 0) {
            const amp = querySTR !== (null && "") ? "?" : "";
            querySTR = querySTR.concat(amp).concat(params);
        }

        if (queryURL !== (null && "") && !paramsExist) {
            return queryURL.concat(querySTR);
        } else if(queryURL !== (null && "")){
            return queryURL.concat("&").concat(querySTR);
        }

        return url;
    }
    returnURL =(url) =>{
        let paramArgs = "dop_params=";
        paramArgs.concat(this.state.returnPARAMSData.toString()).concat("&hash=").concat(this.encode64(this.state.returnPARAMSData.toString()));
        const encryptedParamArgs = this.encode64(paramArgs);
        const param = "dpo_group_return=" + encryptedParamArgs;
        return this.addQueryParam(url, param);
    }
    buildCallbackURL = (paramURL ) =>{
        const redirectURL = this.returnURL( paramURL?.redirectURL);
        return {
            backURL: redirectURL,
            redirectURL: redirectURL
        }
    }
    paymentParams =(bookingId) =>{
        let paramArgs = {
            paymentAmount: 0,
            paymentCurrency: "BWP",
            customerFirstName: this.state.tokenData?.customerFirstName,
            customerLastName: this.state.tokenData?.customerLastName,
            customerAddress: this.state.tokenData?.customerAddress,
            customerCity: this.state.tokenData?.customerCity,
            customerCountry: "BW",
            customerEmail: this.state.tokenData?.customerEmail,
            customerPhone: this.state.tokenData?.customerPhone
        }

        if(this.state.tokenData?.userId !== (null && "")){
            const amountObject = {
                amount: this.state.tokenData?.paymentAmount,
                userId: this.state.tokenData?.userId,
                bookingId: bookingId
            }
            this.setState({
                returnPARAMSData: amountObject
            })

            if(this.state.tokenData?.paymentAmount !== (null && "")){
                paramArgs = {
                    ...paramArgs,
                    paymentAmount: this.state.tokenData?.paymentAmount
                }
            }

            if(this.state.tokenData?.customerCountry !== (null && "")){
                const countryCodes = countryList;
                let countryDetails = countryCodes.filter(country => country.countryInfo.country === this.state.tokenData?.customerCountry);
                if(!countryDetails[0]?.code !== (null || "")){
                    paramArgs ={
                        ...paramArgs,
                        customerCountry: countryDetails[0]?.code
                    }
                } else{
                    countryDetails = countryCodes.filter( country => country.code === this.state.tokenData?.customerCountry);
                    if(countryDetails?.code !== (null || "")){
                        paramArgs ={
                            ...paramArgs,
                            customerCountry: countryDetails?.code
                        }
                    }
                }
            }

            if(this.state.tokenData?.customerPhone !== (null && "")){
                paramArgs = {
                    ...paramArgs,
                    customerPhone: this.state.tokenData?.customerPhone?.replace("+", "").replace("-", "").replace("(", "").replace(")", "")
                }
            }
        }

        paramArgs = {
            ...paramArgs,
            companyToken: this.state.companyToken,
            serviceType: this.state.serviceType,
            serviceDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            referenceText: this.state.referenceText,
            description: "Sharearide booking service"
        }

        const paramURL = {
            backURL: this.state.tokenData?.backURL,
            redirectURL: this.state.tokenData?.redirectURL
        }

        const buildCallBackUrl = this.buildCallbackURL(paramURL);
        paramArgs = {
            ...paramArgs,
            backURL: buildCallBackUrl?.backURL,
            redirectURL: buildCallBackUrl?.redirectURL
        }
        return paramArgs;
    }
    executeDpoRequest = async(postXml) =>{        
        return axiosConfig(
            formatRequest(
              `/api/custom/payment/dpo/initiate`,
              "POST",
              { payload: postXml },
              true,
              true,
              1000 * 60 * 5
            )
        );
    }
    createToken = async(bookingId) =>{
        const paymentArgs = this.paymentParams(bookingId);
        const postXml = `<?xml version='1.0' encoding='utf-8'?>
            <API3G>
                <CompanyToken>${this.state.companyToken}</CompanyToken>
                <Request>createToken</Request>
                <Transaction>
                    <PaymentAmount>${paymentArgs?.paymentAmount}</PaymentAmount>
                    <PaymentCurrency>${paymentArgs?.paymentCurrency}</PaymentCurrency>
                    <CompanyRef>${paymentArgs?.referenceText || ""}</CompanyRef>
                    <customerFirstName>${paymentArgs?.customerFirstName || ""}</customerFirstName>
                    <customerLastName>${paymentArgs?.customerLastName || ""}</customerLastName>
                    <customerAddress>${paymentArgs?.customerAddress || ""}</customerAddress>
                    <customerCity>${paymentArgs?.customerCity || ""}</customerCity>
                    <customerCountry>${paymentArgs?.customerCountry || ""}</customerCountry>
                    <customerPhone>${paymentArgs?.customerPhone || ""}</customerPhone>
                    <customerEmail>${paymentArgs?.customerEmail || ""}</customerEmail>
                    <CompanyRefUnique>0</CompanyRefUnique>
                    <PTL>5</PTL>
                    <RedirectURL>${paymentArgs?.redirectURL}</RedirectURL>
                    <BackURL>${paymentArgs?.backURL}</BackURL>
                </Transaction>
                <Services>
                    <Service>
                        <ServiceType>${this.state.serviceType}</ServiceType>
                        <ServiceDescription>${paymentArgs?.description}</ServiceDescription>
                        <ServiceDate>${paymentArgs?.serviceDate}</ServiceDate>
                    </Service>
                </Services>
            </API3G>`;
        let returnResult = {};
        try{
            /** Generate token */            
            const apiResponse = await this.executeDpoRequest(postXml);
            const { data } = apiResponse.data
            let response = data;
            response = response?.API3G !== (null && "") ? response?.API3G : null;
            if(response?.Result !== (null && "")){
                if(response?.Result !== "000"){
                    returnResult ={
                        ...returnResult,
                        success: "FAIL",
                        error: response?.Result
                    }
                } else{
                    returnResult ={
                        ...returnResult,
                        success: "OK",
                        error: response?.Result,
                        transToken: response?.TransToken,
                        resultExplanation: response?.ResultExplanation,
                        transRef: response?.TransRef
                    }
                }
                return returnResult;
            }
        } catch(e){
            console.log(e);
            returnResult ={
                ...returnResult,
                success: "FAIL",
                error: "Application failed with an unexpected error"
            }
            return returnResult;
        }
    }

    encode64(input) {
        input = escape(input);
        let output = "";
        let chr1, chr2, chr3 = "";
        let enc1, enc2, enc3, enc4 = "";
        let i = 0;
        do {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output = output +
            keyStr.charAt(enc1) +
            keyStr.charAt(enc2) +
            keyStr.charAt(enc3) +
            keyStr.charAt(enc4);
            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);	 
        return output;
    }
    decode64(input) {
        let output = "";
        let chr1, chr2, chr3 = "";
        let enc1, enc2, enc3, enc4 = "";
        let i = 0;
        // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
        const base64test = /[^A-Za-z0-9\+\/\=]/g;
        if (base64test.exec(input)) {
        	alert("There were invalid base64 characters in the input text.\n" +
        	    "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
        	    "Expect errors in decoding."
            );
        }
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        do {
        	enc1 = keyStr.indexOf(input.charAt(i++));
        	enc2 = keyStr.indexOf(input.charAt(i++));
        	enc3 = keyStr.indexOf(input.charAt(i++));
        	enc4 = keyStr.indexOf(input.charAt(i++));
        	chr1 = (enc1 << 2) | (enc2 >> 4);
        	chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        	chr3 = ((enc3 & 3) << 6) | enc4;
        	output = output + String.fromCharCode(chr1);
        	if (enc3 !== 64) {
        	   output = output + String.fromCharCode(chr2);
        	}
        	if (enc4 !== 64) {
        	    output = output + String.fromCharCode(chr3);
        	}
        	chr1 = chr2 = chr3 = "";
        	enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);
        return unescape(output);
    } 

    render() {
        const self = this;
        var listItems = this.state.bookings.map(function(data, key) {
            return (
                <div className="block-content-2 style-3" key={key} style={{padding: '0px'}}>
                    <div>
                        <div className="root-outer p-4" id={'toggler' + data?.uuid }>
                            <div className="route-left">
                                <div className="route-heading"><h5> {data?.departure} </h5></div>
                                <div className="route-date">{data?.booking_date}</div>
                            </div>
                            <div className="route-right">
                                <div className="confirmed-text">
                                    {data?.status === "cancelled" &&
                                        <>
                                            <div className="cancelled">cancelled</div>
                                            <div className="cross-icon"><i className="cross-ico fas fa-times"></i></div>
                                        </>
                                    }
                                    {(data?.is_paid === "Yes" && data?.status === "active") &&
                                            <>
                                                <div className="confirm">confirmed</div>
                                                <div className="check-icon"><i className="fas fa-check"></i></div>
                                            </>
                                    }
                                    {(data?.is_paid === "No" && data?.status === "active") &&
                                        <>
                                            <div className="pending">Pending</div>
                                            <div className="exclamation-icon">
                                                <i className="fas fa-exclamation"></i>
                                            </div>
                                        </>
                                    }
                                    {(data?.status !== "cancelled" && data?.status !== "active") && <>
                                        <div className="pending">Used</div>
                                        <div className="exclamation-icon">
                                            <i className="fas fa-check"></i>
                                        </div>
                                     </>
                                    }
                                </div>
                            </div>
                            <div className="angle-down-1"><a href="#"><i className="fas fa-angle-down"></i></a></div>
                        </div>
                        <UncontrolledCollapse toggler={'#toggler' + data?.uuid }>
                            <div className="time-outer pt-4 pb-4">
                                <div className="time-inner p-3">
                                    <div className="time-left"><strong> {data?.departure} </strong></div>
                                    <div className="time-center"><div className="time-center-bus text-center"><i className="fas fa-bus"></i></div></div>
                                    <div className="time-right">{data?.arrival}</div>
                                </div>
                            </div>
                            <div className="seat-outer">
                                <div className="seat-inner p-5">
                                    <div className="seat-left">
                                        <div className="seat-detail-single">
                                            <div className="seat-detail-name">seat no:</div>
                                            <div className="seat-detail-description"><div className="kuma"> :</div> <div className="color-1"> {data?.seats.slice(1,-1)} </div></div>
                                        </div>
                                        <div className="seat-detail-single">
                                            <div className="seat-detail-name"> <b> Total Fare:</b> </div>
                                            <div className="seat-detail-description"> <div className="kuma"> <b>:</b></div> <div className="color-1"> <b>{data?.ticket_price}</b> </div> </div>
                                        </div>
                                        {data?.is_paid === "Yes" && ( 
                                            <Button color="primary" onClick={async () =>{
                                                await self.props.recentBookingData(data);
                                                self.props.history.push("/user/print-ticket");
                                            }}>Print Ticket(s)</Button>)
                                        }
                                        {(data?.is_paid === "No" && data?.status !== "cancelled") &&
                                            <>
                                                <Button color="secondary" id={data?.uuid + ':' + data?.seats } onClick={()=>self.handleCancelBooking(data)}>Cancel Ticket</Button>
                                                {/* <Button color="primary" id={data?.uuid} onClick={()=>self.handlePayBooking(data)}>Pay Ticket</Button> */}
                                                <div className="cancellation-area" id={"checkbox-area" + data?.uuid }> </div>
                                            </>
                                        }
                                    </div>
                                    <div className="seat-right">
                                        <div className="badge">
                                            {data?.status === "cancelled" && <img src={require('../assets/images/files/cancelled.png')} alt="" />}
                                            {data?.is_paid === "Yes" && <img src={require('../assets/images/files/badge.png')} alt="" />}
                                            {(data?.status !== "cancelled" && data?.is_paid !== "Yes") &&<img src={require('../assets/images/files/pending.png')} alt="" />}
                                        </div>
                                    </div>
                                    <div id={'toggler' + data?.uuid } className="angle-down-1"><a href="#"><i className="fas fa-angle-up"></i></a></div>
                                </div>
                            </div>
                        </UncontrolledCollapse>
                    </div>
                </div>
            );
        }, this);
      
        return (
            this.state.processing ? 
                <div style={{opacity: 0.8, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
                    <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
                        <Spinner animation="grow" variant='success' />
                    </div>
                </div>:
            <>
                <section id="content" className="page-single bg-grey">
                    <div id="content-wrap">
                        <div className="section-flat">
                            <div className="section-content">
                                <Container>
                                    <Row>
                                        <Col lg={3} md={12} className="mb-4">
                                            <UserSidebar />
                                        </Col >
                                        <Col lg={9} md={12} >
                                            <div className="content-main" style={{width: '100%'}}>
                                                {this.state.loading ? <RingLoader color="#02928b" loading={this.state.loading} css={override} size={150} /> :
                                                (this.state.bookings.length > 0 ? listItems :
                                                    <div className="block-content-2 style-3">
                                                        <div>
                                                            <img style={{width: '78px'}} src={require('../assets/images/files/bus-stop.svg')} alt=""/>
                                                            <p style={{fontSize: '30px', color: '#212a42', fontWeight: '600'}}>No Bookings Found</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state) =>{
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        recentBookingData: (data) =>{
            dispatch(recentBookingData(data))
        },
        updateWalletBalance: (data) =>{
            dispatch(updateWalletBalance(data))
        },
        redirectToRecentUrl: (data) => {
            dispatch(redirectToRecentUrl(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);