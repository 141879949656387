import React from "react";
import { Col, Container, Row, Collapse, CardBody, Card, CardHeader,} from "reactstrap";
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import CustomNavbar from "../components/navbars/CustomNavbar";
import axios from "axios";
import { ENDPOINT } from "../constants";

class Faqs extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: 0, cards: [] };
  }

  toggle(e) {
    let event = e.target.dataset.event;
    this.setState({
      collapse: this.state.collapse === Number(event) ? 0 : Number(event),
    });
  }

  async componentDidMount(){
    window.scrollTo(0, 0);
    
    const config = {
      dataType: "json",
      headers: {
        "content-type": "application/json",
      },
    };

    const response = await axios.get(`${ENDPOINT}/app/v1/miscellaneous/faq/all/0/2000`, config);
    const { data = [], success } = response?.data;
    if (success) {
      this.setState({
        loading: false,
        cards: data
      });        
    }
  }

  render() {
    const { collapse } = this.state;
    return (
      <>
        <Header />
        <CustomNavbar />
        <div id="section-faqs" className="section-flat">
          <div className="section-content">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="section-title text-center">
                    <span>
                      <em></em>
                    </span>
                    <h2 className="mt-4">FAQs</h2>
                    <p>Sharearide frequently asked questions.</p>
                  </div>
                </Col>

                <div className="clearfix"></div>
                <Col md={12}>
                  {this.state.cards.map((item, index) => {
                    return (
                      <Card key={index}>
                        <CardHeader onClick={this.toggle} data-event={index}>
                          {item.title}
                        </CardHeader>
                        <Collapse isOpen={collapse === index}>
                          <CardBody>{item.description}</CardBody>
                        </Collapse>
                      </Card>
                    );
                  })}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Faqs;
