export const countryList = [
    {
       "code": "AF",
       "countryInfo": {
          "country": "AFGHANISTAN",
          "short": "AF",
          "short_2": "AFG",
          "code": "004"
       }
    },
    {
       "code": "AL",
       "countryInfo": {
          "country": "ALBANIA",
          "short": "AL",
          "short_2": "ALB",
          "code": "008"
       }
    },
    {
       "code": "DZ",
       "countryInfo": {
          "country": "ALGERIA",
          "short": "DZ",
          "short_2": "DZA",
          "code": "012"
       }
    },
    {
       "code": "AS",
       "countryInfo": {
          "country": "AMERICAN SAMOA",
          "short": "AS",
          "short_2": "ASM",
          "code": "016"
       }
    },
    {
       "code": "AD",
       "countryInfo": {
          "country": "ANDORRA",
          "short": "AD",
          "short_2": "AND",
          "code": "020"
       }
    },
    {
       "code": "AO",
       "countryInfo": {
          "country": "ANGOLA",
          "short": "AO",
          "short_2": "AGO",
          "code": "024"
       }
    },
    {
       "code": "AI",
       "countryInfo": {
          "country": "ANGUILLA",
          "short": "AI",
          "short_2": "AIA",
          "code": "660"
       }
    },
    {
       "code": "AQ",
       "countryInfo": {
          "country": "ANTARCTICA",
          "short": "AQ",
          "short_2": "ATA",
          "code": "010"
       }
    },
    {
       "code": "AG",
       "countryInfo": {
          "country": "ANTIGUA AND BARBUDA",
          "short": "AG",
          "short_2": "ATG",
          "code": "028"
       }
    },
    {
       "code": "AR",
       "countryInfo": {
          "country": "ARGENTINA",
          "short": "AR",
          "short_2": "ARG",
          "code": "032"
       }
    },
    {
       "code": "AM",
       "countryInfo": {
          "country": "ARMENIA",
          "short": "AM",
          "short_2": "ARM",
          "code": "051"
       }
    },
    {
       "code": "AW",
       "countryInfo": {
          "country": "ARUBA",
          "short": "AW",
          "short_2": "ABW",
          "code": "533"
       }
    },
    {
       "code": "AU",
       "countryInfo": {
          "country": "AUSTRALIA",
          "short": "AU",
          "short_2": "AUS",
          "code": "036"
       }
    },
    {
       "code": "AT",
       "countryInfo": {
          "country": "AUSTRIA",
          "short": "AT",
          "short_2": "AUT",
          "code": "040"
       }
    },
    {
       "code": "AZ",
       "countryInfo": {
          "country": "AZERBAIJAN",
          "short": "AZ",
          "short_2": "AZE",
          "code": "031"
       }
    },
    {
       "code": "BS",
       "countryInfo": {
          "country": "BAHAMAS",
          "short": "BS",
          "short_2": "BHS",
          "code": "044"
       }
    },
    {
       "code": "BH",
       "countryInfo": {
          "country": "BAHRAIN",
          "short": "BH",
          "short_2": "BHR",
          "code": "048"
       }
    },
    {
       "code": "BD",
       "countryInfo": {
          "country": "BANGLADESH",
          "short": "BD",
          "short_2": "BGD",
          "code": "050"
       }
    },
    {
       "code": "BB",
       "countryInfo": {
          "country": "BARBADOS",
          "short": "BB",
          "short_2": "BRB",
          "code": "052"
       }
    },
    {
       "code": "BY",
       "countryInfo": {
          "country": "BELARUS",
          "short": "BY",
          "short_2": "BLR",
          "code": "112"
       }
    },
    {
       "code": "BE",
       "countryInfo": {
          "country": "BELGIUM",
          "short": "BE",
          "short_2": "BEL",
          "code": "056"
       }
    },
    {
       "code": "BZ",
       "countryInfo": {
          "country": "BELIZE",
          "short": "BZ",
          "short_2": "BLZ",
          "code": "084"
       }
    },
    {
       "code": "BJ",
       "countryInfo": {
          "country": "BENIN",
          "short": "BJ",
          "short_2": "BEN",
          "code": "204"
       }
    },
    {
       "code": "BM",
       "countryInfo": {
          "country": "BERMUDA",
          "short": "BM",
          "short_2": "BMU",
          "code": "060"
       }
    },
    {
       "code": "BT",
       "countryInfo": {
          "country": "BHUTAN",
          "short": "BT",
          "short_2": "BTN",
          "code": "064"
       }
    },
    {
       "code": "BO",
       "countryInfo": {
          "country": "BOLIVIA",
          "short": "BO",
          "short_2": "BOL",
          "code": "068"
       }
    },
    {
       "code": "BA",
       "countryInfo": {
          "country": "BOSNIA AND HERZEGOVINA",
          "short": "BA",
          "short_2": "BIH",
          "code": "070"
       }
    },
    {
       "code": "BW",
       "countryInfo": {
          "country": "BOTSWANA",
          "short": "BW",
          "short_2": "BWA",
          "code": "072"
       }
    },
    {
       "code": "BV",
       "countryInfo": {
          "country": "BOUVET ISLAND",
          "short": "BV",
          "short_2": "BVT",
          "code": "074"
       }
    },
    {
       "code": "BR",
       "countryInfo": {
          "country": "BRAZIL",
          "short": "BR",
          "short_2": "BRA",
          "code": "076"
       }
    },
    {
       "code": "IO",
       "countryInfo": {
          "country": "BRITISH INDIAN OCEAN TERRITORY",
          "short": "IO",
          "short_2": "IOT",
          "code": "086"
       }
    },
    {
       "code": "BN",
       "countryInfo": {
          "country": "BRUNEI DARUSSALAM",
          "short": "BN",
          "short_2": "BRN",
          "code": "096"
       }
    },
    {
       "code": "BG",
       "countryInfo": {
          "country": "BULGARIA",
          "short": "BG",
          "short_2": "BGR",
          "code": "100"
       }
    },
    {
       "code": "BF",
       "countryInfo": {
          "country": "BURKINA FASO",
          "short": "BF",
          "short_2": "BFA",
          "code": "854"
       }
    },
    {
       "code": "BI",
       "countryInfo": {
          "country": "BURUNDI",
          "short": "BI",
          "short_2": "BDI",
          "code": "108"
       }
    },
    {
       "code": "KH",
       "countryInfo": {
          "country": "CAMBODIA",
          "short": "KH",
          "short_2": "KHM",
          "code": "116"
       }
    },
    {
       "code": "CM",
       "countryInfo": {
          "country": "CAMEROON",
          "short": "CM",
          "short_2": "CMR",
          "code": "120"
       }
    },
    {
       "code": "CA",
       "countryInfo": {
          "country": "CANADA",
          "short": "CA",
          "short_2": "CAN",
          "code": "124"
       }
    },
    {
       "code": "CV",
       "countryInfo": {
          "country": "CAPE VERDE",
          "short": "CV",
          "short_2": "CPV",
          "code": "132"
       }
    },
    {
       "code": "KY",
       "countryInfo": {
          "country": "CAYMAN ISLANDS",
          "short": "KY",
          "short_2": "CYM",
          "code": "136"
       }
    },
    {
       "code": "CF",
       "countryInfo": {
          "country": "CENTRAL AFRICAN REPUBLIC",
          "short": "CF",
          "short_2": "CAF",
          "code": "140"
       }
    },
    {
       "code": "TD",
       "countryInfo": {
          "country": "CHAD",
          "short": "TD",
          "short_2": "TCD",
          "code": "148"
       }
    },
    {
       "code": "CL",
       "countryInfo": {
          "country": "CHILE",
          "short": "CL",
          "short_2": "CHL",
          "code": "152"
       }
    },
    {
       "code": "CN",
       "countryInfo": {
          "country": "CHINA",
          "short": "CN",
          "short_2": "CHN",
          "code": "156"
       }
    },
    {
       "code": "CX",
       "countryInfo": {
          "country": "CHRISTMAS ISLAND",
          "short": "CX",
          "short_2": "CXR",
          "code": "162"
       }
    },
    {
       "code": "CC",
       "countryInfo": {
          "country": "COCOS (KEELING) ISLANDS",
          "short": "CC",
          "short_2": "CCK",
          "code": "166"
       }
    },
    {
       "code": "CO",
       "countryInfo": {
          "country": "COLOMBIA",
          "short": "CO",
          "short_2": "COL",
          "code": "170"
       }
    },
    {
       "code": "KM",
       "countryInfo": {
          "country": "COMOROS",
          "short": "KM",
          "short_2": "COM",
          "code": "174"
       }
    },
    {
       "code": "CG",
       "countryInfo": {
          "country": "CONGO",
          "short": "CG",
          "short_2": "COG",
          "code": "178"
       }
    },
    {
       "code": "CK",
       "countryInfo": {
          "country": "COOK ISLANDS",
          "short": "CK",
          "short_2": "COK",
          "code": "184"
       }
    },
    {
       "code": "CR",
       "countryInfo": {
          "country": "COSTA RICA",
          "short": "CR",
          "short_2": "CRI",
          "code": "188"
       }
    },
    {
       "code": "CI",
       "countryInfo": {
          "country": "COTE D'IVOIRE",
          "short": "CI",
          "short_2": "CIV",
          "code": "384"
       }
    },
    {
       "code": "HR",
       "countryInfo": {
          "country": "CROATIA (local name: Hrvatska)",
          "short": "HR",
          "short_2": "HRV",
          "code": "191"
       }
    },
    {
       "code": "CU",
       "countryInfo": {
          "country": "CUBA",
          "short": "CU",
          "short_2": "CUB",
          "code": "192"
       }
    },
    {
       "code": "CY",
       "countryInfo": {
          "country": "CYPRUS",
          "short": "CY",
          "short_2": "CYP",
          "code": "196"
       }
    },
    {
       "code": "CZ",
       "countryInfo": {
          "country": "CZECH REPUBLIC",
          "short": "CZ",
          "short_2": "CZE",
          "code": "203"
       }
    },
    {
       "code": "DK",
       "countryInfo": {
          "country": "DENMARK",
          "short": "DK",
          "short_2": "DNK",
          "code": "208"
       }
    },
    {
       "code": "DJ",
       "countryInfo": {
          "country": "DJIBOUTI",
          "short": "DJ",
          "short_2": "DJI",
          "code": "262"
       }
    },
    {
       "code": "DM",
       "countryInfo": {
          "country": "DOMINICA",
          "short": "DM",
          "short_2": "DMA",
          "code": "212"
       }
    },
    {
       "code": "DO",
       "countryInfo": {
          "country": "DOMINICAN REPUBLIC",
          "short": "DO",
          "short_2": "DOM",
          "code": "214"
       }
    },
    {
       "code": "TL",
       "countryInfo": {
          "country": "EAST TIMOR",
          "short": "TL",
          "short_2": "TLS",
          "code": "626"
       }
    },
    {
       "code": "EC",
       "countryInfo": {
          "country": "ECUADOR",
          "short": "EC",
          "short_2": "ECU",
          "code": "218"
       }
    },
    {
       "code": "EG",
       "countryInfo": {
          "country": "EGYPT",
          "short": "EG",
          "short_2": "EGY",
          "code": "818"
       }
    },
    {
       "code": "SV",
       "countryInfo": {
          "country": "EL SALVADOR",
          "short": "SV",
          "short_2": "SLV",
          "code": "222"
       }
    },
    {
       "code": "GQ",
       "countryInfo": {
          "country": "EQUATORIAL GUINEA",
          "short": "GQ",
          "short_2": "GNQ",
          "code": "226"
       }
    },
    {
       "code": "ER",
       "countryInfo": {
          "country": "ERITREA",
          "short": "ER",
          "short_2": "ERI",
          "code": "232"
       }
    },
    {
       "code": "EE",
       "countryInfo": {
          "country": "ESTONIA",
          "short": "EE",
          "short_2": "EST",
          "code": "233"
       }
    },
    {
       "code": "ET",
       "countryInfo": {
          "country": "ETHIOPIA",
          "short": "ET",
          "short_2": "ETH",
          "code": "210"
       }
    },
    {
       "code": "FK",
       "countryInfo": {
          "country": "FALKLAND ISLANDS (MALVINAS)",
          "short": "FK",
          "short_2": "FLK",
          "code": "238"
       }
    },
    {
       "code": "FO",
       "countryInfo": {
          "country": "FAROE ISLANDS",
          "short": "FO",
          "short_2": "FRO",
          "code": "234"
       }
    },
    {
       "code": "FJ",
       "countryInfo": {
          "country": "FIJI",
          "short": "FJ",
          "short_2": "FJI",
          "code": "242"
       }
    },
    {
       "code": "FI",
       "countryInfo": {
          "country": "FINLAND",
          "short": "FI",
          "short_2": "FIN",
          "code": "246"
       }
    },
    {
       "code": "FR",
       "countryInfo": {
          "country": "FRANCE",
          "short": "FR",
          "short_2": "FRA",
          "code": "250"
       }
    },
    {
       "code": "FX",
       "countryInfo": {
          "country": "FRANCE, METROPOLITAN",
          "short": "FX",
          "short_2": "FXX",
          "code": "249"
       }
    },
    {
       "code": "GF",
       "countryInfo": {
          "country": "FRENCH GUIANA",
          "short": "GF",
          "short_2": "GUF",
          "code": "254"
       }
    },
    {
       "code": "PF",
       "countryInfo": {
          "country": "FRENCH POLYNESIA",
          "short": "PF",
          "short_2": "PYF",
          "code": "258"
       }
    },
    {
       "code": "TF",
       "countryInfo": {
          "country": "FRENCH SOUTHERN TERRITORIES",
          "short": "TF",
          "short_2": "ATF",
          "code": "260"
       }
    },
    {
       "code": "GA",
       "countryInfo": {
          "country": "GABON",
          "short": "GA",
          "short_2": "GAB",
          "code": "266"
       }
    },
    {
       "code": "GM",
       "countryInfo": {
          "country": "GAMBIA",
          "short": "GM",
          "short_2": "GMB",
          "code": "270"
       }
    },
    {
       "code": "GE",
       "countryInfo": {
          "country": "GEORGIA",
          "short": "GE",
          "short_2": "GEO",
          "code": "268"
       }
    },
    {
       "code": "DE",
       "countryInfo": {
          "country": "GERMANY",
          "short": "DE",
          "short_2": "DEU",
          "code": "276"
       }
    },
    {
       "code": "GH",
       "countryInfo": {
          "country": "GHANA",
          "short": "GH",
          "short_2": "GHA",
          "code": "288"
       }
    },
    {
       "code": "GI",
       "countryInfo": {
          "country": "GIBRALTAR",
          "short": "GI",
          "short_2": "GIB",
          "code": "292"
       }
    },
    {
       "code": "GR",
       "countryInfo": {
          "country": "GREECE",
          "short": "GR",
          "short_2": "GRC",
          "code": "300"
       }
    },
    {
       "code": "GL",
       "countryInfo": {
          "country": "GREENLAND",
          "short": "GL",
          "short_2": "GRL",
          "code": "304"
       }
    },
    {
       "code": "GD",
       "countryInfo": {
          "country": "GRENADA",
          "short": "GD",
          "short_2": "GRD",
          "code": "308"
       }
    },
    {
       "code": "GP",
       "countryInfo": {
          "country": "GUADELOUPE",
          "short": "GP",
          "short_2": "GLP",
          "code": "312"
       }
    },
    {
       "code": "GU",
       "countryInfo": {
          "country": "GUAM",
          "short": "GU",
          "short_2": "GUM",
          "code": "316"
       }
    },
    {
       "code": "GT",
       "countryInfo": {
          "country": "GUATEMALA",
          "short": "GT",
          "short_2": "GTM",
          "code": "320"
       }
    },
    {
       "code": "GN",
       "countryInfo": {
          "country": "GUINEA",
          "short": "GN",
          "short_2": "GIN",
          "code": "324"
       }
    },
    {
       "code": "GW",
       "countryInfo": {
          "country": "GUINEA-BISSAU",
          "short": "GW",
          "short_2": "GNB",
          "code": "624"
       }
    },
    {
       "code": "GY",
       "countryInfo": {
          "country": "GUYANA",
          "short": "GY",
          "short_2": "GUY",
          "code": "328"
       }
    },
    {
       "code": "HT",
       "countryInfo": {
          "country": "HAITI",
          "short": "HT",
          "short_2": "HTI",
          "code": "332"
       }
    },
    {
       "code": "HM",
       "countryInfo": {
          "country": "HEARD ISLAND & MCDONALD ISLANDS",
          "short": "HM",
          "short_2": "HMD",
          "code": "334"
       }
    },
    {
       "code": "HN",
       "countryInfo": {
          "country": "HONDURAS",
          "short": "HN",
          "short_2": "HND",
          "code": "340"
       }
    },
    {
       "code": "HK",
       "countryInfo": {
          "country": "HONG KONG",
          "short": "HK",
          "short_2": "HKG",
          "code": "344"
       }
    },
    {
       "code": "HU",
       "countryInfo": {
          "country": "HUNGARY",
          "short": "HU",
          "short_2": "HUN",
          "code": "348"
       }
    },
    {
       "code": "IS",
       "countryInfo": {
          "country": "ICELAND",
          "short": "IS",
          "short_2": "ISL",
          "code": "352"
       }
    },
    {
       "code": "IN",
       "countryInfo": {
          "country": "INDIA",
          "short": "IN",
          "short_2": "IND",
          "code": "356"
       }
    },
    {
       "code": "ID",
       "countryInfo": {
          "country": "INDONESIA",
          "short": "ID",
          "short_2": "IDN",
          "code": "360"
       }
    },
    {
       "code": "IR",
       "countryInfo": {
          "country": "IRAN, ISLAMIC REPUBLIC OF",
          "short": "IR",
          "short_2": "IRN",
          "code": "364"
       }
    },
    {
       "code": "IQ",
       "countryInfo": {
          "country": "IRAQ",
          "short": "IQ",
          "short_2": "IRQ",
          "code": "368"
       }
    },
    {
       "code": "IE",
       "countryInfo": {
          "country": "IRELAND",
          "short": "IE",
          "short_2": "IRL",
          "code": "372"
       }
    },
    {
       "code": "IL",
       "countryInfo": {
          "country": "ISRAEL",
          "short": "IL",
          "short_2": "ISR",
          "code": "376"
       }
    },
    {
       "code": "IT",
       "countryInfo": {
          "country": "ITALY",
          "short": "IT",
          "short_2": "ITA",
          "code": "380"
       }
    },
    {
       "code": "JM",
       "countryInfo": {
          "country": "JAMAICA",
          "short": "JM",
          "short_2": "JAM",
          "code": "388"
       }
    },
    {
       "code": "JP",
       "countryInfo": {
          "country": "JAPAN",
          "short": "JP",
          "short_2": "JPN",
          "code": "392"
       }
    },
    {
       "code": "JO",
       "countryInfo": {
          "country": "JORDAN",
          "short": "JO",
          "short_2": "JOR",
          "code": "400"
       }
    },
    {
       "code": "KZ",
       "countryInfo": {
          "country": "KAZAKHSTAN",
          "short": "KZ",
          "short_2": "KAZ",
          "code": "398"
       }
    },
    {
       "code": "KE",
       "countryInfo": {
          "country": "KENYA",
          "short": "KE",
          "short_2": "KEN",
          "code": "404"
       }
    },
    {
       "code": "KI",
       "countryInfo": {
          "country": "KIRIBATI",
          "short": "KI",
          "short_2": "KIR",
          "code": "296"
       }
    },
    {
       "code": "KP",
       "countryInfo": {
          "country": "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
          "short": "KP",
          "short_2": "PRK",
          "code": "408"
       }
    },
    {
       "code": "KR",
       "countryInfo": {
          "country": "KOREA, REPUBLIC OF",
          "short": "KR",
          "short_2": "KOR",
          "code": "410"
       }
    },
    {
       "code": "KW",
       "countryInfo": {
          "country": "KUWAIT",
          "short": "KW",
          "short_2": "KWT",
          "code": "414"
       }
    },
    {
       "code": "KG",
       "countryInfo": {
          "country": "KYRGYZSTAN",
          "short": "KG",
          "short_2": "KGZ",
          "code": "417"
       }
    },
    {
       "code": "LA",
       "countryInfo": {
          "country": "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
          "short": "LA",
          "short_2": "LAO",
          "code": "418"
       }
    },
    {
       "code": "LV",
       "countryInfo": {
          "country": "LATVIA",
          "short": "LV",
          "short_2": "LVA",
          "code": "428"
       }
    },
    {
       "code": "LB",
       "countryInfo": {
          "country": "LEBANON",
          "short": "LB",
          "short_2": "LBN",
          "code": "422"
       }
    },
    {
       "code": "LS",
       "countryInfo": {
          "country": "LESOTHO",
          "short": "LS",
          "short_2": "LSO",
          "code": "426"
       }
    },
    {
       "code": "LR",
       "countryInfo": {
          "country": "LIBERIA",
          "short": "LR",
          "short_2": "LBR",
          "code": "430"
       }
    },
    {
       "code": "LY",
       "countryInfo": {
          "country": "LIBYAN ARAB JAMAHIRIYA",
          "short": "LY",
          "short_2": "LBY",
          "code": "434"
       }
    },
    {
       "code": "LI",
       "countryInfo": {
          "country": "LIECHTENSTEIN",
          "short": "LI",
          "short_2": "LIE",
          "code": "438"
       }
    },
    {
       "code": "LT",
       "countryInfo": {
          "country": "LITHUANIA",
          "short": "LT",
          "short_2": "LTU",
          "code": "440"
       }
    },
    {
       "code": "LU",
       "countryInfo": {
          "country": "LUXEMBOURG",
          "short": "LU",
          "short_2": "LUX",
          "code": "442"
       }
    },
    {
       "code": "MO",
       "countryInfo": {
          "country": "MACAU",
          "short": "MO",
          "short_2": "MAC",
          "code": "446"
       }
    },
    {
       "code": "MK",
       "countryInfo": {
          "country": "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
          "short": "MK",
          "short_2": "MKD",
          "code": "807"
       }
    },
    {
       "code": "MG",
       "countryInfo": {
          "country": "MADAGASCAR",
          "short": "MG",
          "short_2": "MDG",
          "code": "450"
       }
    },
    {
       "code": "MW",
       "countryInfo": {
          "country": "MALAWI",
          "short": "MW",
          "short_2": "MWI",
          "code": "454"
       }
    },
    {
       "code": "MY",
       "countryInfo": {
          "country": "MALAYSIA",
          "short": "MY",
          "short_2": "MYS",
          "code": "458"
       }
    },
    {
       "code": "MV",
       "countryInfo": {
          "country": "MALDIVES",
          "short": "MV",
          "short_2": "MDV",
          "code": "462"
       }
    },
    {
       "code": "ML",
       "countryInfo": {
          "country": "MALI",
          "short": "ML",
          "short_2": "MLI",
          "code": "466"
       }
    },
    {
       "code": "MT",
       "countryInfo": {
          "country": "MALTA",
          "short": "MT",
          "short_2": "MLT",
          "code": "470"
       }
    },
    {
       "code": "MH",
       "countryInfo": {
          "country": "MARSHALL ISLANDS",
          "short": "MH",
          "short_2": "MHL",
          "code": "584"
       }
    },
    {
       "code": "MQ",
       "countryInfo": {
          "country": "MARTINIQUE",
          "short": "MQ",
          "short_2": "MTQ",
          "code": "474"
       }
    },
    {
       "code": "MR",
       "countryInfo": {
          "country": "MAURITANIA",
          "short": "MR",
          "short_2": "MRT",
          "code": "478"
       }
    },
    {
       "code": "MU",
       "countryInfo": {
          "country": "MAURITIUS",
          "short": "MU",
          "short_2": "MUS",
          "code": "480"
       }
    },
    {
       "code": "YT",
       "countryInfo": {
          "country": "MAYOTTE",
          "short": "YT",
          "short_2": "MYT",
          "code": "175"
       }
    },
    {
       "code": "MX",
       "countryInfo": {
          "country": "MEXICO",
          "short": "MX",
          "short_2": "MEX",
          "code": "484"
       }
    },
    {
       "code": "FM",
       "countryInfo": {
          "country": "MICRONESIA, FEDERATED STATES OF",
          "short": "FM",
          "short_2": "FSM",
          "code": "583"
       }
    },
    {
       "code": "MD",
       "countryInfo": {
          "country": "MOLDOVA, REPUBLIC OF",
          "short": "MD",
          "short_2": "MDA",
          "code": "498"
       }
    },
    {
       "code": "MC",
       "countryInfo": {
          "country": "MONACO",
          "short": "MC",
          "short_2": "MCO",
          "code": "492"
       }
    },
    {
       "code": "MN",
       "countryInfo": {
          "country": "MONGOLIA",
          "short": "MN",
          "short_2": "MNG",
          "code": "496"
       }
    },
    {
       "code": "MS",
       "countryInfo": {
          "country": "MONTSERRAT",
          "short": "MS",
          "short_2": "MSR",
          "code": "500"
       }
    },
    {
       "code": "MA",
       "countryInfo": {
          "country": "MOROCCO",
          "short": "MA",
          "short_2": "MAR",
          "code": "504"
       }
    },
    {
       "code": "MZ",
       "countryInfo": {
          "country": "MOZAMBIQUE",
          "short": "MZ",
          "short_2": "MOZ",
          "code": "508"
       }
    },
    {
       "code": "MM",
       "countryInfo": {
          "country": "MYANMAR",
          "short": "MM",
          "short_2": "MMR",
          "code": "104"
       }
    },
    {
       "code": "NA",
       "countryInfo": {
          "country": "NAMIBIA",
          "short": "NA",
          "short_2": "NAM",
          "code": "516"
       }
    },
    {
       "code": "NR",
       "countryInfo": {
          "country": "NAURU",
          "short": "NR",
          "short_2": "NRU",
          "code": "520"
       }
    },
    {
       "code": "NP",
       "countryInfo": {
          "country": "NEPAL",
          "short": "NP",
          "short_2": "NPL",
          "code": "524"
       }
    },
    {
       "code": "NL",
       "countryInfo": {
          "country": "NETHERLANDS",
          "short": "NL",
          "short_2": "NLD",
          "code": "528"
       }
    },
    {
       "code": "AN",
       "countryInfo": {
          "country": "NETHERLANDS ANTILLES",
          "short": "AN",
          "short_2": "ANT",
          "code": "530"
       }
    },
    {
       "code": "NC",
       "countryInfo": {
          "country": "NEW CALEDONIA",
          "short": "NC",
          "short_2": "NCL",
          "code": "540"
       }
    },
    {
       "code": "NZ",
       "countryInfo": {
          "country": "NEW ZEALAND",
          "short": "NZ",
          "short_2": "NZL",
          "code": "554"
       }
    },
    {
       "code": "NI",
       "countryInfo": {
          "country": "NICARAGUA",
          "short": "NI",
          "short_2": "NIC",
          "code": "558"
       }
    },
    {
       "code": "NE",
       "countryInfo": {
          "country": "NIGER",
          "short": "NE",
          "short_2": "NER",
          "code": "562"
       }
    },
    {
       "code": "NG",
       "countryInfo": {
          "country": "NIGERIA",
          "short": "NG",
          "short_2": "NGA",
          "code": "566"
       }
    },
    {
       "code": "NU",
       "countryInfo": {
          "country": "NIUE",
          "short": "NU",
          "short_2": "NIU",
          "code": "570"
       }
    },
    {
       "code": "NF",
       "countryInfo": {
          "country": "NORFOLK ISLAND",
          "short": "NF",
          "short_2": "NFK",
          "code": "574"
       }
    },
    {
       "code": "MP",
       "countryInfo": {
          "country": "NORTHERN MARIANA ISLANDS",
          "short": "MP",
          "short_2": "MNP",
          "code": "580"
       }
    },
    {
       "code": "NO",
       "countryInfo": {
          "country": "NORWAY",
          "short": "NO",
          "short_2": "NOR",
          "code": "578"
       }
    },
    {
       "code": "OM",
       "countryInfo": {
          "country": "OMAN",
          "short": "OM",
          "short_2": "OMN",
          "code": "512"
       }
    },
    {
       "code": "PK",
       "countryInfo": {
          "country": "PAKISTAN",
          "short": "PK",
          "short_2": "PAK",
          "code": "586"
       }
    },
    {
       "code": "PW",
       "countryInfo": {
          "country": "PALAU",
          "short": "PW",
          "short_2": "PLW",
          "code": "585"
       }
    },
    {
       "code": "PA",
       "countryInfo": {
          "country": "PANAMA",
          "short": "PA",
          "short_2": "PAN",
          "code": "591"
       }
    },
    {
       "code": "PG",
       "countryInfo": {
          "country": "PAPUA NEW GUINEA",
          "short": "PG",
          "short_2": "PNG",
          "code": "598"
       }
    },
    {
       "code": "PY",
       "countryInfo": {
          "country": "PARAGUAY",
          "short": "PY",
          "short_2": "PRY",
          "code": "600"
       }
    },
    {
       "code": "PE",
       "countryInfo": {
          "country": "PERU",
          "short": "PE",
          "short_2": "PER",
          "code": "604"
       }
    },
    {
       "code": "PH",
       "countryInfo": {
          "country": "PHILIPPINES",
          "short": "PH",
          "short_2": "PHL",
          "code": "608"
       }
    },
    {
       "code": "PN",
       "countryInfo": {
          "country": "PITCAIRN",
          "short": "PN",
          "short_2": "PCN",
          "code": "612"
       }
    },
    {
       "code": "PL",
       "countryInfo": {
          "country": "POLAND",
          "short": "PL",
          "short_2": "POL",
          "code": "616"
       }
    },
    {
       "code": "PT",
       "countryInfo": {
          "country": "PORTUGAL",
          "short": "PT",
          "short_2": "PRT",
          "code": "620"
       }
    },
    {
       "code": "PR",
       "countryInfo": {
          "country": "PUERTO RICO",
          "short": "PR",
          "short_2": "PRI",
          "code": "630"
       }
    },
    {
       "code": "QA",
       "countryInfo": {
          "country": "QATAR",
          "short": "QA",
          "short_2": "QAT",
          "code": "634"
       }
    },
    {
       "code": "RE",
       "countryInfo": {
          "country": "REUNION",
          "short": "RE",
          "short_2": "REU",
          "code": "638"
       }
    },
    {
       "code": "RO",
       "countryInfo": {
          "country": "ROMANIA",
          "short": "RO",
          "short_2": "ROU",
          "code": "642"
       }
    },
    {
       "code": "RU",
       "countryInfo": {
          "country": "RUSSIAN FEDERATION",
          "short": "RU",
          "short_2": "RUS",
          "code": "643"
       }
    },
    {
       "code": "RW",
       "countryInfo": {
          "country": "RWANDA",
          "short": "RW",
          "short_2": "RWA",
          "code": "646"
       }
    },
    {
       "code": "KN",
       "countryInfo": {
          "country": "SAINT KITTS AND NEVIS",
          "short": "KN",
          "short_2": "KNA",
          "code": "659"
       }
    },
    {
       "code": "LC",
       "countryInfo": {
          "country": "SAINT LUCIA",
          "short": "LC",
          "short_2": "LCA",
          "code": "662"
       }
    },
    {
       "code": "VC",
       "countryInfo": {
          "country": "SAINT VINCENT AND THE GRENADINES",
          "short": "VC",
          "short_2": "VCT",
          "code": "670"
       }
    },
    {
       "code": "WS",
       "countryInfo": {
          "country": "SAMOA",
          "short": "WS",
          "short_2": "WSM",
          "code": "882"
       }
    },
    {
       "code": "SM",
       "countryInfo": {
          "country": "SAN MARINO",
          "short": "SM",
          "short_2": "SMR",
          "code": "674"
       }
    },
    {
       "code": "ST",
       "countryInfo": {
          "country": "SAO TOME AND PRINCIPE",
          "short": "ST",
          "short_2": "STP",
          "code": "678"
       }
    },
    {
       "code": "SA",
       "countryInfo": {
          "country": "SAUDI ARABIA",
          "short": "SA",
          "short_2": "SAU",
          "code": "682"
       }
    },
    {
       "code": "SN",
       "countryInfo": {
          "country": "SENEGAL",
          "short": "SN",
          "short_2": "SEN",
          "code": "686"
       }
    },
    {
       "code": "RS",
       "countryInfo": {
          "country": "SERBIA",
          "short": "RS",
          "short_2": "SRB",
          "code": "688"
       }
    },
    {
       "code": "SC",
       "countryInfo": {
          "country": "SEYCHELLES",
          "short": "SC",
          "short_2": "SYC",
          "code": "690"
       }
    },
    {
       "code": "SL",
       "countryInfo": {
          "country": "SIERRA LEONE",
          "short": "SL",
          "short_2": "SLE",
          "code": "694"
       }
    },
    {
       "code": "SG",
       "countryInfo": {
          "country": "SINGAPORE",
          "short": "SG",
          "short_2": "SGP",
          "code": "702"
       }
    },
    {
       "code": "SK",
       "countryInfo": {
          "country": "SLOVAKIA (Slovak Republic)",
          "short": "SK",
          "short_2": "SVK",
          "code": "703"
       }
    },
    {
       "code": "SI",
       "countryInfo": {
          "country": "SLOVENIA",
          "short": "SI",
          "short_2": "SVN",
          "code": "705"
       }
    },
    {
       "code": "SB",
       "countryInfo": {
          "country": "SOLOMON ISLANDS",
          "short": "SB",
          "short_2": "SLB",
          "code": "90"
       }
    },
    {
       "code": "SO",
       "countryInfo": {
          "country": "SOMALIA",
          "short": "SO",
          "short_2": "SOM",
          "code": "706"
       }
    },
    {
       "code": "ZA",
       "countryInfo": {
          "country": "SOUTH AFRICA",
          "short": "ZA",
          "short_2": "ZAF",
          "code": "710"
       }
    },
    {
       "code": "ES",
       "countryInfo": {
          "country": "SPAIN",
          "short": "ES",
          "short_2": "ESP",
          "code": "724"
       }
    },
    {
       "code": "LK",
       "countryInfo": {
          "country": "SRI LANKA",
          "short": "LK",
          "short_2": "LKA",
          "code": "144"
       }
    },
    {
       "code": "SH",
       "countryInfo": {
          "country": "SAINT HELENA",
          "short": "SH",
          "short_2": "SHN",
          "code": "654"
       }
    },
    {
       "code": "PM",
       "countryInfo": {
          "country": "SAINT PIERRE AND MIQUELON",
          "short": "PM",
          "short_2": "SPM",
          "code": "666"
       }
    },
    {
       "code": "SD",
       "countryInfo": {
          "country": "SUDAN",
          "short": "SD",
          "short_2": "SDN",
          "code": "736"
       }
    },
    {
       "code": "SR",
       "countryInfo": {
          "country": "SURINAME",
          "short": "SR",
          "short_2": "SUR",
          "code": "740"
       }
    },
    {
       "code": "SJ",
       "countryInfo": {
          "country": "SVALBARD AND JAN MAYEN ISLANDS",
          "short": "SJ",
          "short_2": "SJM",
          "code": "744"
       }
    },
    {
       "code": "SZ",
       "countryInfo": {
          "country": "SWAZILAND",
          "short": "SZ",
          "short_2": "SWZ",
          "code": "748"
       }
    },
    {
       "code": "SE",
       "countryInfo": {
          "country": "SWEDEN",
          "short": "SE",
          "short_2": "SWE",
          "code": "752"
       }
    },
    {
       "code": "CH",
       "countryInfo": {
          "country": "SWITZERLAND",
          "short": "CH",
          "short_2": "CHE",
          "code": "756"
       }
    },
    {
       "code": "SY",
       "countryInfo": {
          "country": "SYRIAN ARAB REPUBLIC",
          "short": "SY",
          "short_2": "SYR",
          "code": "760"
       }
    },
    {
       "code": "TW",
       "countryInfo": {
          "country": "TAIWAN, PROVINCE OF CHINA",
          "short": "TW",
          "short_2": "TWN",
          "code": "158"
       }
    },
    {
       "code": "TJ",
       "countryInfo": {
          "country": "TAJIKISTAN",
          "short": "TJ",
          "short_2": "TJK",
          "code": "762"
       }
    },
    {
       "code": "TZ",
       "countryInfo": {
          "country": "TANZANIA, UNITED REPUBLIC OF",
          "short": "TZ",
          "short_2": "TZA",
          "code": "834"
       }
    },
    {
       "code": "TH",
       "countryInfo": {
          "country": "THAILAND",
          "short": "TH",
          "short_2": "THA",
          "code": "764"
       }
    },
    {
       "code": "TG",
       "countryInfo": {
          "country": "TOGO",
          "short": "TG",
          "short_2": "TGO",
          "code": "768"
       }
    },
    {
       "code": "TK",
       "countryInfo": {
          "country": "TOKELAU",
          "short": "TK",
          "short_2": "TKL",
          "code": "772"
       }
    },
    {
       "code": "TO",
       "countryInfo": {
          "country": "TONGA",
          "short": "TO",
          "short_2": "TON",
          "code": "776"
       }
    },
    {
       "code": "TT",
       "countryInfo": {
          "country": "TRINIDAD AND TOBAGO",
          "short": "TT",
          "short_2": "TTO",
          "code": "780"
       }
    },
    {
       "code": "TN",
       "countryInfo": {
          "country": "TUNISIA",
          "short": "TN",
          "short_2": "TUN",
          "code": "788"
       }
    },
    {
       "code": "TR",
       "countryInfo": {
          "country": "TURKEY",
          "short": "TR",
          "short_2": "TUR",
          "code": "792"
       }
    },
    {
       "code": "TM",
       "countryInfo": {
          "country": "TURKMENISTAN",
          "short": "TM",
          "short_2": "TKM",
          "code": "795"
       }
    },
    {
       "code": "TC",
       "countryInfo": {
          "country": "TURKS AND CAICOS ISLANDS",
          "short": "TC",
          "short_2": "TCA",
          "code": "796"
       }
    },
    {
       "code": "TV",
       "countryInfo": {
          "country": "TUVALU",
          "short": "TV",
          "short_2": "TUV",
          "code": "798"
       }
    },
    {
       "code": "UG",
       "countryInfo": {
          "country": "UGANDA",
          "short": "UG",
          "short_2": "UGA",
          "code": "800"
       }
    },
    {
       "code": "UA",
       "countryInfo": {
          "country": "UKRAINE",
          "short": "UA",
          "short_2": "UKR",
          "code": "804"
       }
    },
    {
       "code": "AE",
       "countryInfo": {
          "country": "UNITED ARAB EMIRATES",
          "short": "AE",
          "short_2": "ARE",
          "code": "784"
       }
    },
    {
       "code": "GB",
       "countryInfo": {
          "country": "UNITED KINGDOM",
          "short": "GB",
          "short_2": "GBR",
          "code": "826"
       }
    },
    {
       "code": "US",
       "countryInfo": {
          "country": "UNITED STATES",
          "short": "US",
          "short_2": "USA",
          "code": "840"
       }
    },
    {
       "code": "UM",
       "countryInfo": {
          "country": "UNITED STATES MINOR OUTLYING ISLANDS",
          "short": "UM",
          "short_2": "UMI",
          "code": "581"
       }
    },
    {
       "code": "UY",
       "countryInfo": {
          "country": "URUGUAY",
          "short": "UY",
          "short_2": "URY",
          "code": "858"
       }
    },
    {
       "code": "UZ",
       "countryInfo": {
          "country": "UZBEKISTAN",
          "short": "UZ",
          "short_2": "UZB",
          "code": "860"
       }
    },
    {
       "code": "VU",
       "countryInfo": {
          "country": "VANUATU",
          "short": "VU",
          "short_2": "VUT",
          "code": "548"
       }
    },
    {
       "code": "VA",
       "countryInfo": {
          "country": "VATICAN CITY STATE (HOLY SEE)",
          "short": "VA",
          "short_2": "VAT",
          "code": "336"
       }
    },
    {
       "code": "VE",
       "countryInfo": {
          "country": "VENEZUELA",
          "short": "VE",
          "short_2": "VEN",
          "code": "862"
       }
    },
    {
       "code": "VN",
       "countryInfo": {
          "country": "VIET NAM",
          "short": "VN",
          "short_2": "VNM",
          "code": "704"
       }
    },
    {
       "code": "VG",
       "countryInfo": {
          "country": "VIRGIN ISLANDS (BRITISH)",
          "short": "VG",
          "short_2": "VGB",
          "code": "92"
       }
    },
    {
       "code": "VI",
       "countryInfo": {
          "country": "VIRGIN ISLANDS (U.S.)",
          "short": "VI",
          "short_2": "VIR",
          "code": "850"
       }
    },
    {
       "code": "WF",
       "countryInfo": {
          "country": "WALLIS AND FUTUNA ISLANDS",
          "short": "WF",
          "short_2": "WLF",
          "code": "876"
       }
    },
    {
       "code": "EH",
       "countryInfo": {
          "country": "WESTERN SAHARA",
          "short": "EH",
          "short_2": "ESH",
          "code": "732"
       }
    },
    {
       "code": "YE",
       "countryInfo": {
          "country": "YEMEN",
          "short": "YE",
          "short_2": "YEM",
          "code": "887"
       }
    },
    {
       "code": "YU",
       "countryInfo": {
          "country": "YUGOSLAVIA",
          "short": "YU",
          "short_2": "YUG",
          "code": "891"
       }
    },
    {
       "code": "ZR",
       "countryInfo": {
          "country": "ZAIRE",
          "short": "ZR",
          "short_2": "ZAR",
          "code": "180"
       }
    },
    {
       "code": "ZM",
       "countryInfo": {
          "country": "ZAMBIA",
          "short": "ZM",
          "short_2": "ZMB",
          "code": "894"
       }
    },
    {
       "code": "ZW",
       "countryInfo": {
          "country": "ZIMBABWE",
          "short": "ZW",
          "short_2": "ZWE",
          "code": "716"
       }
    }
]