import React from "react";
import { connect } from 'react-redux';

function Header() {

  return (
    <header id="header">
      
    </header>
  );
}

const mapStateToProps = (state) =>{
  return {
    ...state,
  }
}

export default connect(mapStateToProps, null)(Header);
