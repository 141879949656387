import React, { useState, useEffect } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import { connect } from "react-redux";
import { ENDPOINT } from "../constants";
import axios from "axios";
import axiosConfig from "../config/axiosConfig";
import { formatRequest } from "../sagas/common";
import { createBrowserHistory } from 'history';

const DPO_GROUP_MERCHANT_TOKEN = "8D3DA73D-9D7F-4E09-96D4-3D44E7A83EA3";

function PaymentWebhooks({
    data,
    companyToken,
    ...props
}){

    const [msg, setMsg] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const history = createBrowserHistory();
    
    useEffect(() => {
        const response = convertQueryParams(props.location?.search)
        /**To update booking details that the payment is done */
        if(response?.TransID){
            processPaymentResponse(response);
        }
    }, [props.location]);

    const convertQueryParams = (string) => {
        let queryParams = {};
        // remove first letter from query string
        string = string.substring(1);
        // convert string to array
        const queryParamsArray = string.split("&");
        // covert each index query to object
        queryParamsArray.forEach((queryParam) => {
            const queryParamArray = queryParam.split("=");
            // console.log("queryParamArray", queryParamArray);
            queryParams[queryParamArray[0]] = queryParamArray[1];
        });
        return queryParams;
    };

    const processPaymentResponse = (response)=>{
        try {
            verifyTokenURL(response);
        } catch (e) {
            console.log(e);
            setMsg("Error occurred processing your booking payment, and your account was not debited. If there was a debit to your transaction, please reach out to us through our communication channel with the reference number sent to your primary contact")
        }
    }

    const tokenVerifier = async(token ) =>{
        let returnCODE = "";
        let verified   = false;
        const postXml = `<?xml version='1.0' encoding='utf-8'?>
            <API3G>
                <CompanyToken>${DPO_GROUP_MERCHANT_TOKEN}</CompanyToken>
                <Request>verifyToken</Request>
                <TransactionToken>${token}</TransactionToken>
            </API3G>`;
        
        let apiResponse = await axiosConfig(
            formatRequest(
              `/api/custom/payment/dpo/verify-token`,
              "POST",
              { payload: postXml},
              true,
              true,
              1000 * 60 * 5
            )
        );
        const { data } = apiResponse.data

        let response = data;
        response = response?.API3G !== (null && "") ? response?.API3G : null;
        if(response?.Result !== (null && "")){
            verified = true;
            returnCODE = response?.Result
        }
        let returnResult = {
            success: verified ? "OK" : "FAIL",
            returnCODE: returnCODE,
            returnData: response
        }
        return returnResult;
    }

    const navigateToBookings = () =>{
        setTimeout(function() {
            history.push({
                pathname: "/user/bookings",
            });
            window.location.reload();
        }, 30 * 1000);   
    }

    const verifyTokenURL = async(response ) =>{
        if(response?.TransactionToken !== (null && "")){
            const verify = await tokenVerifier(response?.TransactionToken);
            const returnCODE = verify?.returnCODE;
            if (returnCODE !== (null && "") && returnCODE === "000") {
                try{
                    const cacheData = JSON.parse( window.localStorage.getItem("ACTIVE_BOOKING") || "{}" ) || {};
                    const config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + props.Auth?.token,
                        },
                        dataType: "json",
                        method: 'put',
                        url: `${ENDPOINT}/app/v1/transaction/bookings/update-with-payment/${cacheData?.bookingId}`,
                        data : {
                            isPaid: verify?.success === "OK" ? "Yes": "No",
                            referenceNumber: response?.TransID
                        }
                    };
                    const apiResponse = await axios(config);
                    const { success } = apiResponse?.data;
                    if(success){
                        setMsg("Your payment has been acknowledged and saved on our records")
                    } else{
                        setMsg("Your payment has been acknowledged but we encountered an error on our servers to save your transaction. Please query with the transaction reference in future concerning this event")
                    }
                }catch(e){
                    console.log(e)
                    setMsg("Error occurred processing your payment details on our servers but your payment has been credited. Please reach to us if you do not receiver acknowledgement from us within 2 working days")
                } finally{
                    setIsLoading(false);
                    navigateToBookings();
                }
            }else{
                setMsg("Error occurred processing your booking payment, and your account was not debited. If there was a debit to your transaction, please reach out to us through our communication channel with the reference number sent to your primary contact")
                setIsLoading(false)
                navigateToBookings();
            }
        }else{
            setMsg("Error occurred processing your booking payment, and your account was not debited. If there was a debit to your transaction, please reach out to us through our communication channel with the reference number sent to your primary contact")
            setIsLoading(false)
            navigateToBookings();
        }
        window.localStorage.setItem("ACTIVE_BOOKING", null);
    }

    return (
        isLoading ? 
            <div style={{opacity: 0.8, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
                <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
                    <Spinner animation="grow" variant='success' />
                </div>
            </div>:
        <>
            <section className="page-single bg-grey">
                <div id="content-wrap">
                    <div className="section-flat">
                        <div className="section-content">
                            <Container>
                                    <div className="content-main">
                                        <div className="block-content-2 style-3">
                                            <Row>
                                                <Col md={12}>
                                                    <h1 className="txt-center thnx-heading">Thank You!</h1>
                                                    <p style={{marginBottom: '30px'}} className="txt-center">{msg}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                            </Container>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const mapStateToProps = (state) =>{
    return {
        ...state
    }
}

export default connect(mapStateToProps, {})(PaymentWebhooks);