import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./assets/css/css-assets.css";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/main.css";
import "./assets/fonts/fontawesome/css/all.css";
import "react-datepicker/dist/react-datepicker.css"
import * as serviceWorker from "./serviceWorker";
import store, { persister } from "./redux/store";
import { configureAuthentication } from "./sagas/common";
import { firebaseConfig } from "./config/firebaseConfig";
import * as firebase from "firebase/app";
import "firebase/analytics";
import App from "./app";

firebase.initializeApp(firebaseConfig);
configureAuthentication();

ReactDOM.render( 
  <App store={store} persister={persister}/>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
