import React from "react";
import { Container, Row, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Col} from "reactstrap";
import { Link } from "react-router-dom";
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import axios from "axios";
import { connect } from "react-redux";
import { verifySuccess } from "../actions/userActions";
import alertify from "alertifyjs";
import { ENDPOINT } from "../constants";
import CustomNavbar from "../components/navbars/CustomNavbar";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {PhoneNumberUtil} from 'google-libphonenumber';

class Verify extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationCode: "",
      modal: false,
      processing: false,
      selected_option: 'email',
      emailAddress: '',
      contactNumber: ''
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.Auth?.isAuthenticated && this.props.Auth?.token) {
      this.props.history.push("/user/profile");
    }
  }

  handleChange = (e) => {
    this.setState({ verificationCode: e.target.value });
  }

  toggle = async () =>{
    await this.setState({
      modal: !this.state.modal
    });
  }

  requestNewOtp = async() =>{
    await this.setState({
      modal: true
    });
  }

  handleMethodChange = (e) =>{
    if(e.target.value === 'email'){
      this.setState({
        selected_option: 'email',
      });
    }else if(e.target.value === 'cellphone'){
      this.setState({
        selected_option: 'cellphone'
      });
    }
  }

  handlePhoneChange = (value, data, event, formattedValue) =>{
    if(formattedValue !== undefined && formattedValue !== null){
      this.setState({
        contactNumber: formattedValue
      });
    }
  }

  validatePhoneNumber = () =>{
    let valid = false;
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      valid =  phoneUtil.isValidNumber(phoneUtil.parse(this.state.contactNumber));
    } catch(e) {
      valid = false;
    }
    return valid;
  }

  handleNewOtpRequest = async () =>{
    await this.setState({
      processing: true
    });
    var email_el = document.getElementById('emailAddress');
    if(this.state.selected_option === 'email'){
      if(this.state.emailAddress !== ""){
        if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.emailAddress)){
          alertify.notify('Invalid email', 'custom', 2, function(){});
          email_el.style.border = "1px solid #ff8476";
          return;          
        }else{
          email_el.style.border = "1px solid #ddd";
        }
      } else{
        alertify.notify('Email cannot be empty', 'custom', 2, function(){});
        return;
      }
    } else if(this.state.selected_option === 'cellphone'){
      if(this.state.contactNumber === ""){
        alertify.notify('Contact number cannot be empty', 'custom', 2, function(){});
        return;
      }else{
        if(!this.validatePhoneNumber()){
          alertify.notify('Invalid phone number format', 'custom', 2, function(){});
          return;
        }
      }
    }
    const config = {
      dataType: "json",
      headers: {
        "content-type": "application/json",
      }
    }
    if(this.state.selected_option === 'email'){
      const response = await axios.get(`${ENDPOINT}/app/v1/user/user/${this.state.emailAddress}/email`, config);
      if(response.status === 200){
        if(response.data.id !== undefined && response.data.id !== null){
          const res = await this.requestOtp(response.data.id);
          if(res.status === 200){
            alertify.success(res.data, 'custom', 2, function(){});
          } else{
            alertify.notify(res.data, 'custom', 2, function(){});
          }
        } else{
          alertify.notify(response.data, 'custom', 2, function(){});
        }
      }else{
        alertify.notify(response.data, 'custom', 2, function(){});
      }
    } else if(this.state.selected_option === 'cellphone'){
      const response = await axios.get(`${ENDPOINT}/app/v1/user/user/${this.state.contactNumber.replace(/ +/g, "")}/phone`, config);
      if(response.status === 200){
        if(response.data.id !== undefined && response.data.id !== null){
          const res = await this.requestOtp(response.data.id);
          if(res.status === 200){
            alertify.success(res.data, 'custom', 2, function(){});
          } else{
            alertify.notify(res.data, 'custom', 2, function(){});
          }
        } else{
          alertify.notify(response.data, 'custom', 2, function(){});
        }
      }else{
        alertify.notify(response.data, 'custom', 2, function(){});
      }
    }

    await this.setState({
      processing: false,
      modal: false
    });
    
  }

  requestOtp = async (data) =>{
    return await axios.get(`${ENDPOINT}/app/v1/user/${data}/otp`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      dataType: "json"
    });
  }

  handleClick = async (e) => {
    e.preventDefault();    
    var verification_code_el = document.getElementById('verificationCode');
    verification_code_el.style.border = "1px solid #ddd";
    if(!/^[+0-9]+$/.test(this.state.verificationCode)){
      if(this.state.verificationCode === ""){
        alertify.notify('Verification code can not be empty', 'custom', 2, function(){});
        verification_code_el.style.border = "1px solid #ff8476";
        return;
      }else{
        alertify.notify('Verification code should contain only numbers', 'custom', 2, function(){});
        verification_code_el.style.border = "1px solid #ff8476";
        return;
      }
    }else{
      if(this.state.verificationCode.length !== 6){
        alertify.notify('Verification code provided is too long /short', 'custom', 2, function(){});
        verification_code_el.style.border = "1px solid #ff8476";
        return;
      }
      verification_code_el.style.border = "1px solid #ddd";
    }
    await this.setState({
      processing: true
    });
    const config = {
      dataType: "json",
      headers: {
        "content-type": "application/json",
      }
    }

    const response = await axios.get(`${ENDPOINT}/v1/app/global/otp/security/${this.state.verificationCode}/auth`, config);
    if(response.status === 200){
      alertify.success("Account verified successfully", 'custom', 2, function(){});
      await this.props.verifySuccess(true);
      this.props.history.push("/login");
    }else{
      alertify.notify(response.data, 'custom', 2, function(){});
    }
    await this.setState({
      processing: false
    });
  };

  render() {
    return (
      this.state.processing ? 
        <div style={{opacity: 0.8, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
          <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
            <Spinner animation="grow" variant='success' />
          </div>
        </div>:
      <>
        <Header />
        <CustomNavbar />
        <Modal isOpen={this.state.modal} toggle={this.toggle} className=''>
            <ModalHeader toggle={this.toggle}>Request New OTP</ModalHeader>
            <ModalBody>
              <form id="form-login" className="rounded">
                <div className="form-content">
                  <div className="booking-cost">
                    <Row>
                      <Col md={4}>
                        <div className="btn-setting">
                          <div className="btn-setting-1">
                            <input type="radio" value="email" style={{color: '#02928b'}} onChange={this.handleMethodChange} checked={this.state.selected_option === 'email'} name="method" /> Email 
                          </div>
                          <div className="btn-setting-2">
                          &nbsp;&nbsp;<input type="radio" value="cellphone" style={{color: '#02928b'}} onChange={this.handleMethodChange} checked={this.state.selected_option === 'cellphone'} name="method" /> &nbsp;Cellphone
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {this.state.selected_option === 'email' ? 
                    <div className="form-group">
                      <label htmlFor="emailAddress"> Email Address </label>
                      <input
                        type="text"
                        name="emailAddress"
                        id="emailAddress"
                        className="form-control"
                        value={this.state.emailAddress}
                        onChange={e=>this.setState({ emailAddress: e.target.value })} />
                    </div> :
                    <div className="form-group">
                      <label htmlFor="verificationCode"> Cellphone Number </label>
                      <PhoneInput
                        enableAreaCodes={true}
                        name="cellphone"
                        id="registerContactNumber"
                        className="form-control"
                        country={'bw'}
                        value={this.state.contactNumber}
                        onChange={this.handlePhoneChange} 
                        isValid={(value, country) => {
                          const phoneUtil = PhoneNumberUtil.getInstance();
                          try {
                            if (value.length > 7 && !phoneUtil.isValidNumber(phoneUtil.parse("+" + value ))) {
                              return 'Invalid value: ' + value + ', ' + country.name;
                            } else {
                              return true;
                            }
                          } catch(e) {
                            return 'Invalid value: ' + value + ', ' + country.name;
                          }
                        }}
                      /> 
                    </div>
                  }
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <div className="form-group pt-4">
                <input
                  type="submit"
                  className="form-control rounded"
                  value="Submit"
                  onClick={this.handleNewOtpRequest}/>
              </div>
            </ModalFooter>
        </Modal>
        <section className="popup-preview-2 popup-preview-login login-section pb-90 pt-90">
          <Container>
            <Row className="justify-content-md-center align-items-center">
              <div className="block-content">
                <div className="block-title">
                  <h3>Account Verification</h3>
                  <h5>Please activate your account to continue using Sharearide</h5>
                </div>
                <div className="content">
                  <div className="left">
                    <form id="form-login" className="rounded">
                      <div className="form-content">
                        <div className="form-group">
                          <label htmlFor="verificationCode"> OTP Number </label>
                          <input
                            type="number"
                            name="verificationCode"
                            id="verificationCode"
                            className="form-control"
                            value={this.state.verificationCode}
                            onChange={this.handleChange} />
                        </div>
                        <div className="form-group pt-4">
                          <input
                            type="submit"
                            className="form-control rounded"
                            value="Submit"
                            onClick={this.handleClick}/> 
                            <a href="#" onClick={this.requestNewOtp}>Request New Otp</a>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="foot-msg">
                    <span className="msg">
                      Activated Already? <Link to="/login">Sign in</Link>
                    </span>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifySuccess: (state) =>{dispatch(verifySuccess(state))}
}
};

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
