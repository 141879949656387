import React from "react";
import { Spinner, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter, Col, Input, Label, } from "reactstrap";
import { Link } from "react-router-dom";
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import CustomNavbar from "../components/navbars/CustomNavbar";
import axios from "axios";
import { connect } from 'react-redux';
import { loginSuccess } from '../actions/userActions';
import alertify from "alertifyjs";
import { ENDPOINT } from "../constants";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationCode: "",
      processing: false,
      modal: false,
      selected_option: 'email',
      emailAddress: '',
      contactNumber: '',
      password: '',
      confirmPassword: '',
      otpNumber: '',
      width: '300px'
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    if (this.props.is_logged_in) {
      this.props.history.push("/user/profile");
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (window.innerWidth < 375) {
      this.setState({ width: '300px' });
      return;
    } else if (window.innerWidth < 444) {
      this.setState({ width: '317px' });
      return;
    } else if (window.innerWidth < 478) {
      this.setState({ width: '350px' });
      return;
    } else if (window.innerWidth < 540) {
      this.setState({ width: '400px' });
      return;
    } else if (window.innerWidth < 576) {
      this.setState({ width: '442px' });
      return;
    } else if (window.innerWidth < 670) {
      this.setState({ width: '460px' });
      return;
    } else if (window.innerWidth < 768) {
      this.setState({ width: '460px' });
      return;
    } else {
      this.setState({ width: '600px' });
      return;
    }
  }

  handleChange = (e) => {
    if (e.target.id === 'newPassword') {
      this.setState({ password: e.target.value });
    }
    if (e.target.id === 'newPasswordConfirm') {
      this.setState({ confirmPassword: e.target.value });
    }

    if (e.target.id === 'otpNumber') {
      this.setState({ otpNumber: e.target.value });
    }

  }

  handlePhoneChange = (value, data, event, formattedValue) => {
    if (formattedValue !== undefined && formattedValue !== null) {
      this.setState({
        contactNumber: formattedValue
      });
    }
  }

  validatePhoneNumber = () => {
    let valid = false;
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      valid = phoneUtil.isValidNumber(phoneUtil.parse(this.state.contactNumber));
    } catch (e) {
      valid = false;
    }
    return valid;
  }

  toggle = async () => {
    await this.setState({
      modal: !this.state.modal
    });
  }

  handleMethodChange = (e) => {
    if (e.target.value === 'email') {
      this.setState({
        selected_option: 'email',
      });
    } else if (e.target.value === 'cellphone') {
      this.setState({
        selected_option: 'cellphone'
      });
    }
  }

  handleClick = async (e) => {
    e.preventDefault();
    try {
      var email_el = document.getElementById('emailAddress');
      if (this.state.selected_option === 'email') {
        if (this.state.emailAddress !== "") {
          if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.emailAddress)) {
            alertify.notify('Invalid email', 'custom', 2, function () { });
            email_el.style.border = "1px solid #F98575";
            return;
          } else {
            email_el.style.border = "1px solid #ddd";
          }
        } else {
          alertify.notify('Email cannot be empty', 'custom', 2, function () { });
          return;
        }
      } else if (this.state.selected_option === 'cellphone') {
        if (this.state.contactNumber === "") {
          alertify.notify('Contact number cannot be empty', 'custom', 2, function () { });
          return;
        } else {
          if (!this.validatePhoneNumber()) {
            alertify.notify('Invalid phone number format', 'custom', 2, function () { });
            return;
          }
        }
      }

      await this.setState({
        processing: true
      });

      if (this.state.selected_option === 'email') {
        const payload = {
          email: this.state.emailAddress,
        }
        const response = await this.requestOtp(payload);
        const { success } = response?.data;
        if (success) {
          alertify.success("Request code sent, please check your primary contact for details", 'custom', 2, function () { });
          await this.setState({
            modal: true,
            processing: false,
            selected_option: 'email',
            emailAddress: '',
            contactNumber: ''
          });
        } else {
          alertify.notify("Request failed", 'custom', 2, function () { });
        }
      } else if (this.state.selected_option === 'cellphone') {
        const payload = {
          phone: this.state.contactNumber.replace(/ +/g, ""),
        }
        const response = await this.requestOtp(payload);
        const { success } = response?.data;
        if (success) {
          alertify.success("Request code sent, please check your primary contact for details", 'custom', 2, function () { });
          await this.setState({
            modal: true,
            processing: false,
            selected_option: 'email',
            emailAddress: '',
            contactNumber: ''
          });
        } else {
          alertify.notify("Request failed", 'custom', 2, function () { });
        }
      }
    } catch (e) {

    } finally {
      await this.setState({
        processing: false
      });

    }
  }

  requestOtp = async (payload) => {
    return await axios.post(`${"http://localhost:8082"}/app/uaa/v1/change-password`, payload);
  }

  changePassword = async (e) => {
    e.preventDefault();

    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])(?=.{6,20})/.test(this.state.password)) {
      if (this.state.password === "") {
        alertify.notify('Password cannot be empty', 'custom', 2, function () { });
        return;
      } else {
        alertify.notify('Password should only contain atleast Uppercase, lowercase, special characters and be 6 to 20 characters long', 'custom', 2, function () { });
        return;
      }
    }

    if (this.state.password !== this.state.confirmPassword) {
      alertify.notify('Password do not match', 'custom', 2, function () { });
      return;
    }

    if (!/^[+0-9]+$/.test(this.state.otpNumber)) {
      if (this.state.otpNumber === "") {
        alertify.notify('Otp can not be empty', 'custom', 2, function () { });
        return;
      } else {
        alertify.notify('Otp should contain only numbers', 'custom', 2, function () { });
        return;
      }
    } else {
      if (this.state.otpNumber.length !== 6) {
        alertify.notify('Otp provided is too long /short', 'custom', 2, function () { });
        return;
      }
    }

    await this.setState({
      processing: true,
    });

    const res = await axios.put(`${ENDPOINT}/app/uaa/v1/change-request/password/${this.state.otpNumber}/${this.state.password}/otp`);
    const { success } = res?.data;
    if (success) {
      alertify.success("Account password changed successfully", 'custom', 2, function () { });
      await this.setState({
        modal: false,
        processing: false,
        selected_option: 'email',
        emailAddress: '',
        contactNumber: ''
      });
      this.props.history.push("/login");
    } else {
      await this.setState({
        modal: false,
        processing: false,
      });
      alertify.notify("Password change failed", 'custom', 2, function () { });
    }
  }

  render() {
    return (
      this.state.processing ?
        <div style={{ opacity: 0.8, backgroundColor: '#ccc', position: 'fixed', width: '100%', height: '100%', top: '0px', left: '0px', zIndex: 1000 }}>
          <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
            <Spinner animation="grow" variant='success' />
          </div>
        </div> :
        <>
          <Header />
          <CustomNavbar />
          <Modal isOpen={this.state.modal} toggle={this.toggle} className=''>
            <ModalHeader toggle={this.toggle}>New Password</ModalHeader>
            <ModalBody>
              <form id="form-login" className="rounded">
                <div className="form-content">
                  <div className="box-field">
                    <Label for="newPassword">New Password</Label>
                    <Input
                      type="password"
                      name="newPassword"
                      id="newPassword"
                      className="form-control"
                      placeholder=""
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="box-field">
                    <Label for="newPasswordConfirm">Confirm Password</Label>
                    <Input
                      type="password"
                      name="newPasswordConfirm"
                      id="newPasswordConfirm"
                      className="form-control"
                      placeholder=""
                      value={this.state.confirmPassword}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="box-field">
                    <Label for="otpNumber">Otp Code</Label>
                    <Input
                      type="number"
                      name="otpNumber"
                      id="otpNumber"
                      className="form-control"
                      placeholder=""
                      value={this.state.otpNumber}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <div className="form-group pt-4">
                <input
                  type="submit"
                  className="form-control rounded"
                  value="Submit"
                  onClick={this.changePassword} />
              </div>
            </ModalFooter>
          </Modal>

          <section className="popup-preview-2 popup-preview-login login-section pb-90 pt-90">
            <Container>
              <Row className="justify-content-md-center align-items-center">
                <div className="block-content">
                  <div className="block-title">
                    <h3>Forget Password</h3>
                    <h5>Please enter below your registered email /cellphone to reset password!</h5>
                  </div>
                  <div className="content">
                    <Row className="align-items-left" style={{ marginTop: 10.5 }}>
                      <Col xs={12}>
                        <div >
                          <form id="form-login" className="rounded">
                            <div className="booking-cost">
                              <Row>
                                <Col md={12}>
                                  <div className="btn-setting">
                                    <div className="btn-setting-1">
                                      <input type="radio" value="email" style={{ color: '#549A8B' }} onChange={this.handleMethodChange} checked={this.state.selected_option === 'email'} name="method" /> Email
                                    </div>
                                    <div className="btn-setting-2">
                                      &nbsp;&nbsp;<input type="radio" value="cellphone" style={{ color: '#549A8B' }} onChange={this.handleMethodChange} checked={this.state.selected_option === 'cellphone'} name="method" /> &nbsp;Cellphone
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="form-content">
                              {this.state.selected_option === 'email' ?
                                <Row>
                                  <Col xs={12}>
                                    <div className="form-group">
                                      <label htmlFor="emailAddress"> Email Address </label>
                                      <input
                                        type="text"
                                        name="emailAddress"
                                        id="emailAddress"
                                        className="form-control"
                                        value={this.state.emailAddress}
                                        onChange={e => this.setState({ emailAddress: e.target.value })} />
                                    </div>
                                  </Col>
                                </Row> :
                                <Row>
                                  <Col xs={12}>
                                    <div className="form-group">
                                      <label htmlFor="verificationCode"> Cellphone Number </label>
                                      <PhoneInput
                                        inputStyle={{
                                          width: this.state.width,
                                        }}
                                        enableAreaCodes={true}
                                        name="cellphone"
                                        id="registerContactNumber"
                                        country={'bw'}
                                        value={this.state.contactNumber}
                                        onChange={this.handlePhoneChange}
                                        isValid={(value, country) => {
                                          const phoneUtil = PhoneNumberUtil.getInstance();
                                          try {
                                            if (value.length > 7 && !phoneUtil.isValidNumber(phoneUtil.parse("+" + value))) {
                                              return 'Invalid value: ' + value + ', ' + country.name;
                                            } else {
                                              return true;
                                            }
                                          } catch (e) {
                                            return 'Invalid value: ' + value + ', ' + country.name;
                                          }
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              }
                              <div className="form-group pt-4">
                                <Row>
                                  <Col md={8} xs={6}>
                                    <a href="#" onClick={() => this.setState({ modal: true })}>Have Otp?</a>
                                  </Col>
                                  <Col md={4} xs={6}>
                                    <input
                                      type="submit"
                                      className="form-control rounded"
                                      value="Rest Password"
                                      onClick={this.handleClick}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </form>
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-left foot-msg">
                      <Col xs={12}>
                        <div className="foot-msg">
                          <span className="msg">
                            Not a member yet? <Link to="/signup">Sign up</Link> for free
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Row>
            </Container>
          </section>
          <Footer />
        </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    is_logged_in: state.Auth.isAuthenticated,
    token: state.token,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginSuccess: (token) => { dispatch(loginSuccess(token)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
