import React from "react"; 
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core components
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import CustomNavbar from "../components/navbars/CustomNavbar";
//terms item list json file
import termList from "./termList";

class RefundPolicy extends React.Component {
  constructor() {
    super();
    this.state = {
      terms: termList,
    };
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  render() {
    const termList = this.state.terms.map((item, i) => {
      return (
        <React.Fragment key={i}>
          <h4 className="mb-4">{item.heading}</h4>
          <p className="mb-5">{item.text}</p>
        </React.Fragment>
      );
    });

    return (
      <>
        <Header />
        <CustomNavbar />
        <div id="section-terms-conditions" className="section-flat">
          <div className="section-content">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="section-title text-center">
                    <span>
                      <em></em>
                    </span>
                    <h2 className="mt-4">
                      Refund Policy
                    </h2>
                    {/* <p>
                      Cum doctus civibus efficiantur in imperdiet deterruisset.
                    </p> */}
                  </div>
                </Col>

                <div className="clearfix"></div>
                <Col sm={12}>
                  {/* <h4 className="mb-3 privacy-heading">1	ROLE OF SHARE A RIDE</h4> */}
                  <ol type="A">
                    <li className="mb-5 privacy-heading">GENERALITIES
                      <ul>
                        <li className="li-inner">Requests for the cancellation of tickets purchased through Sharearide , will only be
received up to 24 hours before the departure of the bus. The refund is subject to the
fulfilment of the indicated conditions and to the acceptance of the order by our
customer service centre. Requests for refunds must be sent via our website on the
Web Cancellation option. The refund request will be evaluated and you will receive a
confirmation or rejection via email.</li>
                        <li className="li-inner">The refund will be accepted to the buyer of the tickets, only when all the tickets of
the purchase are cancelled. There is no exception for partial cancellations within the
same purchase.</li>
                        <li className="li-inner"><b style={{color: '#000'}}>Tickets purchased through Sharearide web, app or mobile web, are subject to the
penalties provided.</b></li>
                        <li className="li-inner">Some transport companies, by their own policy, do not accept cancellations and only
allow delays (requested by the customer in person) or change of booking. You,
therefore, will not be able to cancel the tickets from these transport companies, as
they do not allow Sharearide to cancel your tickets.</li>
                        <li className="li-inner">Some transport companies, by their policy, only allow cancellation within a
maximum period of 7 days after the purchase. You, therefore, can not cancel the
tickets from these transport companies after the 7 day period has elapsed, as they
do not allow Sharearide to cancel your tickets.</li>
                        <li className="li-inner">Cancellations do not apply to tickets that have been exchanged at the transportation
company. In this case, the cancellation of the trip and the return must be
coordinated directly with the transport company.</li>
                        <li className="li-inner">In the case of a user who purchases with Cash Payment, the return will be made by a
transfer or deposit to the bank account of the owner of the purchase. Alternatively,
a cash refund can be collected from the nearest Sharearide Cash Agent.</li>
                        <li className="li-inner">The buyer is the only person authorized to request and receive a refund. Sharearide
will issue a refund within 3 to 20 business days from the date the refund request was
accepted.</li>
                        <li className="li-inner">Sharearide will not be responsible for cancellations/modifications of bus service by
the transport company. In this case, you must communicate directly with the
transport company to manage the change or the return of your ticket.</li>
                        <li className="li-inner">In the case of modifications or postponements of the ticket, the client must
communicate directly with the transport company. The applications are subject to
the policies of the companies. Sharearide does not make modifications and/or
postponements.</li>
                        <li className="li-inner">You may contact your transport provider to change ticket details or request for any
alterations. Sharearide is not responsible for any alterations that you have made
after purchasing the tickets from the platform.</li>
                      </ul>
                    </li>
                  </ol>
                  <h4 className="cancellation-heading">CANCELLATION OF TICKET</h4>
                  <ol>
                        <li className="li-inner">Cancellation of tickets can be done through our User’s Login on the Sharearide
website or mobile application or by calling the customer care number;</li>
                        <li className="li-inner">Any cancellation is subject to cancellation charges as mentioned on the ticket.</li>
                        <li className="li-inner">User needs to compulsorily obtain a bus ticket at the regular fare in case a child
above the age of 5 years is accompanied by them, unless otherwise a particular bus
operator specifies otherwise in their terms and conditions.</li>
                        <li className="li-inner">If a cancellation is made 0-24 hours before departure, Sharearide will not refund the
ticket.</li>
                        <li className="li-inner">A 5% Cancellation Charge will be applied if a cancellation is made up to 24 hours
before departure.</li>
                        <li className="li-inner">Sharearide can not issue Partial Cancellations</li>
                        <li className="li-inner">It Is Rights of Sharearide To Change Cancellation Policy Any Time Without Prior To
Notice</li>
                        <li className="li-inner">The arrival and departure times mentioned on the ticket are only tentative timings.
Buses may be delayed due to some unavoidable reasons like traffic jams, breakdown
etc. However, the bus will not leave the source before the mentioned time on the
ticket.</li>
                        <li className="li-inner">Passengers are requested to arrive at the Boarding / Pickup point at least 30 minutes
before the scheduled time of departure.</li>
                        <li className="li-inner">Single-seat cancellations is not allowed. The entire booking can only be cancelled.</li>
                        <li className="li-inner">In case the bus gets cancelled, the responsibility of service lies with the transport
operator. Sharearide holds no responsibility of making any alternate arrangements
for transport.</li>
                        <li className="li-inner">In case transport is cancelled due to any unavoidable circumstances the fare shall be
refundable by Sharearide.</li>
                        <li className="li-inner">Sharearide is not responsible for any loss or damage of your valuables/cash left
behind on the bus.</li>
                        <li className="li-inner">In case of cancellation if there are any alternative transport arrangements made by
the transport operator then no amount shall be refunded by Sharearide.</li>
                        <li className="li-inner">Passengers are required to present the following at the time of boarding the bus.
Failing to do so, they will not be allowed to board the bus.</li>
                        <li className="li-inner">A copy of the ticket. (A print out of the ticket OR the print out of the ticket E-Mail)</li>
                        <li className="li-inner">Valid Identity proof, (Driving licence, Student ID card, Omang, Passport,)</li>
                  </ol>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default RefundPolicy;
