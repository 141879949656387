import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Form, FormGroup } from "reactstrap";
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios from "axios";
import alertify from "alertifyjs";
import { connect } from "react-redux";
import { updateSearchFields } from "../actions/searchActions";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import CustomNavbar from "../components/navbars/CustomNavbar";
import moment from 'moment';
import { searchBuses } from "../sagas/global";
import { actions } from "../actions";
import { firebaseDatabase } from "../config/firebaseConfig";
import { ref, onValue } from "firebase/database";
import { createBrowserHistory } from 'history';

const instance = axios.create();
instance.interceptors.request.use(function (response) {
  return response;
}, function (error) {
  if (error.response && error.response.data) {
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});
const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function Search({
  cities,
  from_cities,
  to_cities,
  selectedFromCity,
  selectedToCity,
  searchDate,
  updateSearchFields,
  updateBusRouteId,
  fetchSupportedCities,
  token
}) {
  const [loading, setLoading] = useState(true);
  const [numberOfSearchResult, setNumberOfSearchResult] = useState(0);
  const [availableResults, setAvailableResults] = useState([]);
  const [nothingAvailable, setNothingAvailable] = useState(true);
  const [bookedBusSeats, setBookedBusSeats] = useState([]);
  const [selected_from_city, setSelectedFromCity] = useState(selectedFromCity || {});
  const [selected_to_city, setSelectedToCity] = useState(selectedToCity || {});
  const [date, setDate] = useState(null);
  const history = createBrowserHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (searchDate) {
      setDate(searchDate)
    }
  }, []);

  useEffect(() => {
    try {
      setBookedBusSeats([])
      const timestamp = moment(date).format("YYYY-MM-DD").valueOf()
      const reference = "bookings/".concat(timestamp);
      onValue(ref(firebaseDatabase, reference), (snapshot) => {
        const data = snapshot.val();
        if (data !== null) {
          let busSeats = [];
          const keys = Object.keys(data); //buses
          for (let i = 0; i < keys.length; i++) {
            let seats = [];
            const innerKeys = Object.keys(data[keys[i]]); //seats
            for (let j = 0; j < innerKeys.length; j++) {
              const innerKey = innerKeys[j];
              seats.push(data[keys[i]][innerKey])
            }
            busSeats.push({
              busId: keys[i],
              seats: seats
            });
          }
          setBookedBusSeats(busSeats)
        }
      });
    } catch (_error) {
    }
  }, [date]);

  useEffect(() => {
    const fetchData = async () => {
      if (!cities?.details) {
        fetchSupportedCities();
      }
      try {
        if (searchDate) {
          const response = await searchBuses(moment(searchDate).format('YYYY-MM-DD').valueOf(), selected_from_city.value, selected_to_city.value);
          const { data = [], success } = response?.data;
          if (success) {
            handleRouteChange(data, searchDate);
          }
        }
      } catch (_error) {
      } finally {
        setLoading(false)
      }
    }

    fetchData();
  }, [cities]);

  const handleRouteChange = async (data, _date) => {
    if (data?.length <= 0) {
      setNothingAvailable(true);
    } else {
      setNothingAvailable(false);
      setNumberOfSearchResult(data?.length)
      setAvailableResults(data);
    }
    setLoading(false)
  }

  const handleFromCitySelectChange = (option) => {
    if (selected_to_city !== undefined && selected_to_city !== null) {
      if (selected_to_city.value === option.value) {
        alertify.error('Departure and Destination place can not be the same');
      } else {
        setSelectedFromCity({ value: option.value, label: option.label });
      }
    } else {
      setSelectedFromCity({ value: option.value, label: option.label });
    }
  };

  const handleToCitySelectChange = (option) => {
    if (selected_from_city !== undefined && selected_from_city !== null) {
      if (selected_from_city.value === option.value) {
        alertify.error('Departure and Destination place can not be the same');
      } else {
        setSelectedToCity({ value: option.value, label: option.label });
      }
    } else {
      setSelectedToCity({ value: option.value, label: option.label });
    }
  };

  const handleDateChange = (e) => {
    setDate(e);
  };

  const handleBookingBtn = (result) => {
    updateBusRouteId(result);
    history.push({
      pathname: "/user/booking",
      search: window.location.search,
    });
    window.location.reload();
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    let updatedFields = {
      from: selected_from_city,
      to: selected_to_city,
      date: date,
    };
    setNumberOfSearchResult(0)
    setAvailableResults([]);
    setNothingAvailable(true);

    updateSearchFields(updatedFields);
    try {
      const response = await searchBuses(moment(date).format('YYYY-MM-DD').valueOf(), selected_from_city.value, selected_to_city.value);
      const { data = [], success } = response?.data;
      if (success) {
        handleRouteChange(data, date);
      }
    } catch (_error) {
    } finally {
      setLoading(false)
    }
  };

  const renderSvg = (name) => {
    try {
      return require(`../assets/images/files/${name}.svg`)
    } catch (err) {
      return null;
    }
  }

  const renderTime = (startTime, endTime) => {

    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    const start = new Date();
    start.setHours(startHours, startMinutes, 0);

    const end = new Date();
    end.setHours(endHours, endMinutes, 0);

    let diffMinutes = (end - start) / (1000 * 60);

    if (diffMinutes < 0) {
      diffMinutes += 24 * 60;
    }

    const hours = Math.floor(diffMinutes / 60);
    const minutes = diffMinutes % 60;

    return minutes === 0 ? `${hours}h` : `${hours}h ${minutes}m`;
  }

  const render = () => {
    return (
      <>
        <Header />
        <CustomNavbar />
        <section id="content" className="page-search">
          <div id="content-wrap">
            <div className="section-flat">
              <div className="section-content">
                <Container fluid>
                  <Row>
                    {/* Search Form Column */}
                    <Col xl={4} lg={5} md={12} className="search-box mb-4 mb-xl-0">
                      <Row>
                        <Col md={12} className="mb-3">
                          <div className="result-found-outer">
                            <div className="result-found-inner">
                              <div className="result-found-icon"><img src={require('../assets/images/files/search.svg')} alt="" /></div>
                              <div className="result-found-text">{numberOfSearchResult} results found</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Form className="form-banner-reservation br-tabs-content form-inline style-2 form-h-50 mb-4">
                        <Row>
                          <Col xs={12} className="mb-3">
                            <FormGroup className="w-100">
                              <Select
                                className="w-100"
                                defaultValue={selected_from_city}
                                isLoading={loading}
                                onChange={handleFromCitySelectChange}
                                isSearchable={true}
                                options={from_cities}
                              />
                              <i className="fas fa-plane rotate-up"></i>
                            </FormGroup>
                          </Col>
                          <Col md={12} className="mb-3">
                            <FormGroup className="w-100">
                              <Select
                                className="w-100"
                                style={{ width: "100%" }}
                                defaultValue={selected_to_city}
                                isLoading={loading}
                                onChange={handleToCitySelectChange}
                                isSearchable={true}
                                options={to_cities}
                              />
                              <i className="fas fa-plane rotate-down"></i>
                            </FormGroup>
                          </Col>
                          <Col xs={12} className="mb-3">
                            <FormGroup className="w-100 search-date-picker">
                              <DatePicker
                                className="w-100 riding-date riding-date-search"
                                selected={date}
                                onChange={handleDateChange}
                                minDate={new Date()}
                              />
                              <i className="far fa-calendar"></i>
                            </FormGroup>
                          </Col>
                          <Col lg={12} md={12}>
                            <FormGroup>
                              <Button
                                type="submit"
                                className="form-control icon"
                                onClick={handleSearch}
                              > Search Again
                                <i className="fas fa-search"></i>
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                    <Col xl={8} lg={7} md={12}>
                      <Row>
                        <Col xs={12}>
                          <div className="search-outer search-outer-2 mb-4">
                            <div className="search-inner">
                              <div className="search-heading ">Your Search</div>
                              <div className="search-location">
                                <div className="search-from mr-5">
                                  <div className="search-label"><b> From:{" "} </b></div>
                                  <div className="search-label-content">{' ' + selected_from_city?.label || ""}</div>
                                </div>
                                <div className="search-to mr-5">
                                  <div className="search-label"><b> To:{' '} </b></div>
                                  <div className="search-label-content">{' ' + selected_to_city?.label || ""}</div>
                                </div>
                                <div className="search-date">
                                  <div className="search-label"><b> Date:{' '} </b></div>
                                  <div className="search-label-content">{' ' + month[date?.getMonth()] + ' ' + date?.getDate() + ', ' + date?.getFullYear()}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {loading ?
                        <div className="d-flex justify-content-center py-5">
                          <div className="ring">Searching <span className="span"></span></div>
                        </div> :
                        <div className="page-single-content sidebar-left">
                          {nothingAvailable ?
                            <div className="no-bus-outer p-3 p-md-5 text-center">
                              <div className="no-bus-inner">
                                <div className="no-bus-img pr-5"><img src={require('../assets/images/files/no_bus.png')} alt="" /></div>
                                <div className="main-bus-text">
                                  <div className="no-bus-text">No buses available for route</div>
                                  <div className="no-bus-text-1">Search again</div>
                                </div>
                              </div>
                            </div>
                            :
                            <Row>
                              <Col xs={12}>
                                <div className="route-outer">
                                  {availableResults.map(function (result, key) {
                                    let busBookedSeats = bookedBusSeats.filter(bus => bus?.busId === result?.bus?.uuid)
                                    let bookedSeats = 0;
                                    if (busBookedSeats.length > 0) {
                                      bookedSeats = busBookedSeats[0]?.seats?.filter(seat =>
                                        (seat.status === "RESERVED") &&
                                        (
                                          seat.departureSequence === result?.routeDetails?.busSubRoute?.subRoute?.departureSequenceNumber ||
                                          seat.arrivalSequence >= result?.routeDetails?.busSubRoute?.subRoute?.departureSequenceNumber
                                        )
                                      )?.length || 0;
                                    }

                                    const busFacilities = result?.bus?.Facilities?.map((data, key) => {
                                      return data.name;
                                    });
                                    const totalBusSeats = result?.bus?.totalSeats || 0;
                                    const availaSeats = parseInt(totalBusSeats) - bookedSeats;
                                    return (
                                      <div key={key} className="route-inner p-3 bg-white mb-3">
                                        <Row className="align-items-center">
                                          <Col xs={12} md={3} lg={2} className="mb-3 mb-md-0">
                                            <div className="identity-outer">
                                              <div className="identity-logo">
                                                {result?.bus?.image && <img src={new Buffer.from(result?.bus.image).toString("ascii")} alt="" />}
                                              </div>
                                              <div className="identity-bus">
                                                <img src={require('../assets/images/files/bus.svg')} alt="" />
                                              </div>
                                            </div>
                                          </Col>

                                          <Col xs={12} md={6} lg={8} className="p-0">
                                            <div className="route-name">
                                              <Row>
                                                <Col xs={6} md={4} className="responsive-border-col">
                                                  <div className="main-departure-outer">
                                                    <div className="icon-1 icon-1-svg">
                                                      <img src={require('../assets/images/files/bus.svg')} alt="" />
                                                    </div>
                                                    <div className="departure">
                                                      <div className="departure-time-text" style={{ fontSize: "14px", }}>Bus Reg. No</div>
                                                      <div className="departure-time-integer" style={{ fontSize: "12px", }}>{result?.bus.regNo}</div>
                                                    </div>
                                                  </div>
                                                </Col>

                                                <Col xs={6} md={4} className="responsive-border-col">
                                                  <div className="main-departure-outer">
                                                    <div className="icon-1 icon-1-svg">
                                                      <img src={require('../assets/images/files/route_coral.svg')} alt="" />
                                                    </div>
                                                    <div className="departure">
                                                      <div className="departure-time-text" style={{ fontSize: "14px", }}>Features</div>
                                                      <div className="departure-time-integer" style={{ fontSize: "12px", }}>
                                                        {busFacilities.length > 0 && (busFacilities.map((data, key) =>
                                                          <OverlayTrigger key={key} placement='right' overlay={<Tooltip id={`tooltip-${key}`}> {data} </Tooltip>} >
                                                            {data && renderSvg(data?.toLowerCase()) ?
                                                              <span className="icon-1 icon-1-svg">
                                                                <img src={renderSvg(data?.toLowerCase())} alt={data} style={{ width: "20px" }} />
                                                              </span> : <span className="icon-1 icon-1-svg" style={{ fontSize: "10px" }}> {data?.toLowerCase()}</span>
                                                            }
                                                          </OverlayTrigger>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Col>

                                                <Col xs={6} md={4} className="responsive-border-col">
                                                  <div className="main-departure-outer">
                                                    <div className="icon-1 icon-1-svg">
                                                      <img src={require('../assets/images/files/bus/seat-available.png')} alt="" />
                                                    </div>
                                                    <div className="departure">
                                                      <div className="departure-time-text" style={{ fontSize: "14px", }}>Free Seats</div>
                                                      <div className="departure-time-integer" style={{ fontSize: "12px", }}>{availaSeats} / {totalBusSeats} Seats</div>
                                                    </div>
                                                  </div>
                                                </Col>

                                                <Col xs={6} md={4} className="responsive-border-col">
                                                  <div className="main-departure-outer">
                                                    <div className="icon-1 icon-1-svg">
                                                      <img src={require('../assets/images/files/clock.svg')} alt="" />
                                                    </div>
                                                    <div className="departure">
                                                      <div className="departure-time-text" style={{ fontSize: "14px", }}>Departure Time</div>
                                                      <div className="departure-time-integer" style={{ fontSize: "12px", }}>{result?.routeDetails?.busRoute?.departure}Hrs</div>
                                                    </div>
                                                  </div>
                                                </Col>

                                                <Col xs={6} md={4} className="responsive-border-col">
                                                  <div className="main-departure-outer">
                                                    <div className="icon-1 icon-1-svg">
                                                      <img src={require('../assets/images/files/clock.svg')} alt="" />
                                                    </div>
                                                    <div className="departure">
                                                      <div className="departure-time-text" style={{ fontSize: "14px", }}>Duration</div>
                                                      <div className="departure-time-integer" style={{ fontSize: "12px", }}>{renderTime(result?.routeDetails?.busRoute.departure, result?.routeDetails?.busRoute.arrival)}</div>
                                                    </div>
                                                  </div>
                                                </Col>

                                                <Col xs={6} md={4} className="responsive-border-col">
                                                  <div className="main-departure-outer">
                                                    <div className="icon-1 icon-1-svg">
                                                      <img src={require('../assets/images/files/pin.svg')} alt="" />
                                                    </div>
                                                    <div className="departure">
                                                      <div className="departure-time-text" style={{ fontSize: "14px", }}>Type</div>
                                                      <div className="departure-time-integer" style={{ fontSize: "12px", }}>{result?.busType?.name}</div>
                                                    </div>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </Col>

                                          <Col xs={12} md={3} lg={2} className="p-0 text-center text-md-right">
                                            <div className="curency" style={{ float: "left" }}>BWP {result?.routeDetails?.busSubRoute?.busSubRoute?.fare}</div>
                                            <div className="sold" style={{ float: "left" }}>
                                              <div className="seats-left">Price Per Person</div>
                                              <Button
                                                color="primary" style={{ padding: "10px" }}
                                                className="w-100 mt-3 mt-md-0"
                                                onClick={() => handleBookingBtn(result)}
                                              >
                                                Book Now
                                              </Button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  })}
                                </div>
                              </Col>
                            </Row>
                          }
                          <div className="row"><Col md={12}><div className="content-main" id="bus-listing"></div></Col></div>
                        </div>}
                    </Col>
                  </Row>
                  <Row></Row>
                </Container>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }

  return (render())
}

const mapStateToProps = (state) => {
  return {
    token: state?.Auth?.token,
    from_cities: state?.cities?.details,
    to_cities: state?.cities?.details,
    selectedFromCity: state?.global.search_from,
    selectedToCity: state?.global.search_to,
    searchDate: new Date(state?.global.search_date),
    cities: state?.cities
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSearchFields: (data) => {
      dispatch(updateSearchFields(data));
    },
    updateBusRouteId: (data) => {
      dispatch(actions.booking.updateBusRouteId(data));
    },
    fetchSupportedCities: () => {
      dispatch(actions.cities.fetchCities());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
