import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import routes from '../../routes';
import Footer from "../../components/footers/Footer";
import Header from "../../components/headers/Header";
import CustomNavbar from "../../components/navbars/CustomNavbar";
import EmailActivationMessage from '../../views/EmailActivationMessage';
import { redirectToRecentUrl } from "../../actions/userActions";
import { connect } from 'react-redux';

const User = (props)=>{
    useEffect(()=>{
        if(!props.is_logged_in){
            var data = {
                url: window.location.href
            };
            props.redirectToRecentUrl(data);

            props.history.push("/login");
        }
    }, [props]);

    const getRoutes = routess => {
        return routess.map((prop, key) => {
            if (prop.layout === "/user") {
            return (
                <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
                />
            );
            }else{
            return null;
            }
        });
    };

    if(props.is_logged_in){
        return (
            <>
                <Header />
                <CustomNavbar />
                <div>
                    {!props.isEmailVerified && <EmailActivationMessage />}
                    <Switch>
                        {getRoutes(routes)}
                        <Redirect from="*" to="/user/profile" />
                    </Switch>
                </div>
                <Footer />
            </>
        )
    }else{
        return (
            <>
                <div>Loading...</div>
            </>
        )
    }
}

const mapStateToProps = (state) =>{
    return {
        is_logged_in: state.Auth.isAuthenticated,
        token: state.token,
        user: state.Auth.user,
        isEmailVerified: state.Auth.isEmailVerified
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        redirectToRecentUrl: (data) => {dispatch(redirectToRecentUrl(data))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(User);

