import React from "react";
import {
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";

class EmailActivationMessage extends React.Component{
    componentDidMount(){
        window.scrollTo(0, 0);
    }

    render() {
        return (
          <>
            <div style={{backgroundColor: '#F6F6F6', paddingTop: '20px'}}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <Alert style={{margin: '0px'}} color="warning">
                                Please activate your account! <Link to="/user/email-activation">Click Here</Link>
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            </div>
          </>
        )
    }
}
export default EmailActivationMessage;