import UserDashboard from './views/pages/UserDashboard.jsx';
import UserProfile from './views/ProfilePage';
import EmailActivation from './views/EmailActivation.jsx';
import Booking from './views/Booking.jsx';
import BookingOverview from './views/bookingOverview.jsx';
import Bookings from './views/Bookings.jsx';
import UsedBookings from './views/UsedBookings';
import CancelledBookings from './views/cancelledBookings.jsx';
import Ticket from './views/Ticket.jsx';
import ThankYou from './views/ThankYou.jsx';
import Wallet from './views/Wallet.jsx';
import ChangePassword from './views/ChangePassword.jsx';
import Withdraw from './views/Withdraw.jsx';
import Withdrawals from './views/Withdrawals.jsx';
import UpcomingTickets from './views/UpcomingTickets.jsx';

var routes = [ 
    {
      path: "/home",
      name: "User Home",
      component: UserDashboard,
      layout: "/user"
    },
    {
      path: "/profile",
      name: "User Profile",
      component: UserProfile,
      layout: "/user"
    },
    {
      path: "/email-activation",
      name: "Account Activation",
      component: EmailActivation,
      layout: "/user"
    },
    {
      path: "/booking",
      name: "Booking",
      component: Booking,
      layout: "/user"
    },
    {
      path: "/booking-overview",
      name: "Booking Overview",
      component: BookingOverview,
      layout: "/user"
    },
    {
      path: "/bookings",
      name: "Bookings",
      component: Bookings,
      layout: "/user"
    },
    {
      path: "/print-ticket",
      name: "Print Ticket",
      component: Ticket,
      layout: "/user"
    },
    {
      path: "/upcoming-tickets",
      name: "Print Ticket",
      component: UpcomingTickets,
      layout: "/user"
    },
    {
      path: "/thank-you",
      name: "Thank You",
      component: ThankYou,
      layout: "/user"
    },
    {
      path: "/used-bookings",
      name: "Used Bookings",
      component: UsedBookings,
      layout: "/user"
    },
    {
      path: "/cancelled-bookings",
      name: "Cancelled Bookings",
      component: CancelledBookings,
      layout: "/user"
    },
    {
      path: "/wallet",
      name: "Wallet",
      component: Wallet,
      layout: "/user"
    },
    {
      path: "/change-password",
      name: "Change Password",
      component: ChangePassword,
      layout: "/user"
    },
    {
      path: "/withdraw",
      name: "Withdraw",
      component: Withdraw,
      layout: "/user"
    },
    {
      path: "/withdrawals",
      name: "Withdrawals",
      component: Withdrawals,
      layout: "/user"
    },
];

export default routes;
