export const loginSuccess = (data) =>{
    return {
        type: 'LOGIN_SUCCESS',
        data: data,
    }
}

export const signupSuccess = (user) =>{
    return {
        type: 'SIGNUP_SUCCESS',
        user: user
    }
}

export const verifySuccess = (data) =>{
    return {
        type: 'LOGIN_SUCCESS',
        data: data,
    }
}

export const logout = () =>{
    return {
        type: 'LOGOUT',
    }
}

export const profileUpdate = (data) =>{
    return {
        type: 'PROFILE_UPDATE',
        data: data
    }
}

export const emailActivationSucces = (data) =>{
    return {
        type: 'EMAIL_ACTIVATION_SUCCESS',
        data: data
    }
}

export const redirectToRecentUrl = (data) =>{
    return {
        type: 'REDIRECT_TO_RECENT_URL',
        data: data
    }
}