import axios from "../config/axiosConfig";
import { formatRequest } from "./common";

export function fetchDashboardData(role) {
  return axios(
    formatRequest(
      `/api/v1/app/global/${role}`,
      "get",
      "",
      true,
      true
    )
  );
}

export function publicStatistics() {
  return axios(
    formatRequest(
      `/api/v1/app/global/public`,
      "get",
      "",
      true,
      true
    )
  );
}

export function searchBuses(day, departure, destination) {
  return axios(
    formatRequest(
      `/api/v2/app/global/search/${day}/${departure}/${destination}`,
      "get",
      "",
      true,
      true
    )
  );
}