import { put, takeLatest, all, spawn } from "redux-saga/effects";
import { events } from "../actions";
import { generateLogin } from "./login";
import { requestChangePassword, } from "./resetPassword";
import { updateToken } from "./common";
import { fetchCitiesRecords} from "./routeManagement";
import { fetchUserDetails} from "./userManagement";
import { fetchCities } from "./Cities";

function* requestSaga() {
  const sagas = [
    {
      event: events.login.REQUEST,
      generator: generateLogin,
      method: takeLatest,
    },
    {
      event: events.auth.REQUEST_REFRESH_JWT_TOKEN,
      generator: updateToken,
      method: takeLatest,
    },
    {
      event: events.changePassword.REQUEST,
      generator: requestChangePassword,
      method: takeLatest,
    },
    {
      event: "FETCH_CITY_LIST",
      generator: fetchCitiesRecords,
      method: takeLatest,
    },
    {
      event: events.user.REQUEST,
      generator: fetchUserDetails,
      method: takeLatest,
    },
    {
      event: events.cities.REQUEST,
      generator: fetchCities,
      method: takeLatest,      
    }
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield saga.method.call(null, saga.event, saga.generator);
            break;
          } catch (e) {
            yield put({ type: events.loading.COMPLETED });
            console.log(e);
          }
        }
      })
    )
  );
}

export default requestSaga;
