export const updateBusRouteId = (data) =>{
    return {
        type: 'UPDATE_BUS_ROUTE_ID',
        data: data,
    }
}

export const updateBusBookingSeats = (data) =>{
    return {
        type: 'UPDATE_BUS_BUS_BOOKING_SEATS',
        data: data,
    }
}

export const updateBusBookingData = (data) =>{
    return {
        type: 'UPDATE_BUS_BOOKING_DATA',
        data: data,
    }
}

export const recentBookingData = (data) =>{
    return {
        type: 'RECENT_BOOKING_DATA',
        data: data,
    }
}

export const updateWalletBalance = (data) =>{
    return {
        type: 'UPDATE_WALLET_BALANCE',
        data: data,
    }
}

export const clearAllSearchRelatedHistory = () =>({
    type: 'PURGE'
})