/**
 * Entry application component used to compose providers and render Routes.
 * */
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./appRoutes";

export default function App({ store, persister }) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <BrowserRouter> 
          <Routes />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
