import React, { useState } from "react";
import {useEffect} from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { Collapse, NavbarText, Navbar, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container,
} from "reactstrap";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import { actions } from "../../actions";
import { persister } from "../../redux/store";

const CustomNavbar = (props) => {
  
  const initialState = {
    not_homepage: false,
  };

  const [open, setOpen] = useState(false);
  const [not_homepage, setHomePage] = useState(initialState);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenn, setIsOpenn] = useState(false);

  const toggler = () => setIsOpen(!isOpen);
  const togglerr = () => setIsOpenn(!isOpenn);

  useEffect(() =>{
    $(window).scroll(function(){ 
      $('nav').toggleClass('scrolled', $(this).scrollTop() > 200); 
    });
    var url = window.location.href;
    var pieces = url.split("/");
    
    if (pieces[4] !== "") {
      setHomePage(currentState =>({
        ...currentState,
        not_homepage: true
      }));      
    }
  }, []);

  const toggle = () => {
    setOpen(!open);
    document.body.style.overflow = open ? "visible" : "hidden";
  };

  const logout = () =>{
    persister.purge();
  }

  return (
    <React.Fragment>
      <Navbar color="light" light expand="lg" sticky="top" className={not_homepage.not_homepage &&('solidnavmenu')}>
        <Container className="nav-container">
          <Row>
          <div className="nav-align">
            <NavbarBrand to="/" tag={Link} className="logo logo-header">
              <img src={require("../../assets/images/files/share-a-ride-logo-white.png")} alt="" />
            </NavbarBrand>            
            {/* <NavbarToggler /> */} 
            <Collapse navbar>
              <Nav className="mr-auto" navbar>
                <NavItem> <NavLink to="/" tag={Link}> Home </NavLink> </NavItem>            
                <NavItem> <NavLink to="/faqs" tag={Link}> Help </NavLink> </NavItem>
                <UncontrolledDropdown nav inNavbar className="for-hover">
                  <DropdownToggle nav > Manage Bookings </DropdownToggle>
                  <DropdownMenu left="true">
                    <DropdownItem> <Link className="user-items" style={{color: '#212529'}} to="/user/bookings" tag={Link}>Bookings</Link> </DropdownItem>
                    <DropdownItem> <Link className="user-items" style={{color: '#212529'}} to="/user/upcoming-tickets" tag={Link}>Tickets</Link> </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/* <NavItem> {props.Auth.isAuthenticated && props.balance ? <NavLink to="/user/wallet" tag={Link}>BWP {props.balance}</NavLink> : "" }</NavItem> */}
                {props.Auth.isAuthenticated ?                  
                    <UncontrolledDropdown nav inNavbar className="for-hover">
                      <DropdownToggle nav >
                        <img alt="" style={{width: '44px', borderRadius: '50%'}} src={props.profile?.details?.user?.image ? new Buffer.from(props.profile?.details?.user?.image).toString("ascii") : require('../../assets/images/files/user_avatar.png')} />
                      </DropdownToggle>
                      <DropdownMenu left="true">
                        <DropdownItem>{props.Auth.user.firstName +' ' + props.Auth.user.lastName}</DropdownItem>
                        <DropdownItem> <Link className="user-items" style={{color: '#212529'}} to="/user/profile" tag={Link}> Profile </Link> </DropdownItem>
                        <DropdownItem onClick={logout}>Logout</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  :
                  <NavLink to="/login" tag={Link}> Login </NavLink>
                }
              </Nav>
            </Collapse>
          </div>
            <div className="menu-mobile-btn" onClick={toggle}>
              <div className="hamburger hamburger--slider">
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </div>
            </div>
          </Row>
        </Container>
      </Navbar>

      <div className={open ? "side-panel-menu open" : "side-panel-menu"}>
        <a className="logo logo-side-panel" href="index-default.html">
          <img
            src={require("../../assets/images/files/share-a-ride-logo-white.png")}
            alt=""
          />          
        </a>
        
        <div className="mobile-side-panel-menu">
          <ul className="menu-mobile list-unstyled">
            <NavItem> <NavLink to="/" tag={Link}> Home </NavLink> </NavItem>            
            <NavItem> <NavLink to="/faqs" tag={Link}> Help </NavLink> </NavItem>
            <NavbarText onClick={toggler} className="mobi-nav-item">Manage Bookings</NavbarText>
            <Collapse isOpen={isOpen}>
              <NavItem className="mobile-sub-menu mob-fix" onClick={toggle}><Link to="/user/bookings" tag={Link}>Bookings</Link></NavItem>
              <NavItem className="mobile-sub-menu mob-fix" onClick={toggle}><Link to="/user/upcoming-tickets" tag={Link}>Tickets</Link></NavItem>
            </Collapse>
            {/* <NavItem> {props.Auth.isAuthenticated && props.balance ? <NavLink to="/user/wallet" tag={Link}>BWP {props.balance}</NavLink> : "" }</NavItem> */}
            {props.Auth.isAuthenticated?
              <>
                <NavbarText onClick={togglerr} className="mobi-nav-item">{<img alt="" style={{width: '44px'}} src={props.Auth.user.profilePicUrl !== null ? new Buffer.from(props.Auth.user.profilePicUrl).toString("ascii") : require('../../assets/images/files/user_avatar.png')} />}</NavbarText>
                <Collapse isOpen={isOpenn}>
                  <NavItem className="mobile-sub-menu mob-fix" onClick={toggle}>
                    {props.Auth.user.firstName +' ' + props.Auth.user.lastName}
                  </NavItem>                  
                  <NavItem className="mobile-sub-menu mob-fix">
                    <Link className="mobile-sub-menu" to="/user/profile" tag={Link} onClick={toggle}>Profile</Link>
                  </NavItem>
                  <NavItem className="mobile-sub-menu mob-fix" onClick={logout}>
                    <NavbarText className="mobile-sub-menu-single" onClick={toggle}>Logout</NavbarText>
                  </NavItem>
                </Collapse>
              </>
              : 
              <NavItem>
                <NavLink to="/login" tag={Link} onClick={toggle}> Login </NavLink>
              </NavItem>
            }
          </ul>
        </div>
        <div className="side-panel-close" onClick={toggle}>
          <div className="hamburger hamburger--slider is-active">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </div>
        </div>
      </div>
      <div
        className={
          open ? "popup-preview-overlay open" : "popup-preview-overlay"
        }
      ></div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) =>{
  return {
    ...state,
    user: state?.Auth?.user || {},
    isLoggedIn: state?.Auth?.isAuthenticated || false,
  }
}

const mapDispatchToProps = (dispatch) =>{
  return {
    fetchProfile: ( userId ) => {
      dispatch(actions.user.fetchUserDetails( userId));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomNavbar);
