import React, { Component } from "react";
import {
    Container,
    Row,
    Col,
  } from "reactstrap";

export default class error extends Component {
  render() {
    return (
      <div className="error-outer">
        <Container>
          <Row>
            <Col md={12}>
                <div className="go-btn-outer">
                    <input type="submit" class="form-control form-control mt-5" value="Go Back"></input>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}


