import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { persister } from "../redux/store";

class UserDashboardSidebar extends React.Component {

    componentDidMount(){
        var url = window.location.href;
        var pieces = url.split("/");

        var elems = document.querySelectorAll("#user-menu-list li");
        for (var i = 0; i < elems.length; i++) {
            elems[i].classList.remove("active");
        }

        if (pieces[pieces.length - 1] === "profile") {
            elems[0].classList.add("active");
        } else if (pieces[pieces.length - 1] === "bookings") {
            elems[1].classList.add("active");
        } else if (pieces[pieces.length - 1] === "cancelled-bookings") {
            elems[3].classList.add("active");
        } else if (pieces[pieces.length - 1] === "used-bookings") {
            elems[2].classList.add("active");
        } else if (pieces[pieces.length - 1] === "change-password") {
            elems[4].classList.add("active");
        }
    }

    handleLogout = () =>{
        persister.purge();
    }

    render() {
        return (
            <>
                <div className="sidebar style-2">
                    <ul className="hi-tabs" id="user-menu-list">
                        <li className="active hi-item">
                            <Link to="/user/profile">
                            <i className="far fa-user"></i>
                            Profile
                            </Link>
                        </li>
                        <li className="hi-item">
                            <Link to="/user/bookings">
                            <i className="fas fa-briefcase"></i>
                            Bookings
                            </Link>
                        </li>
                        <li className="hi-item">
                            <Link to="/user/used-bookings">
                                <i className="fas fa-credit-card"></i>
                                Used Bookings
                            </Link>
                        </li>
                        <li className="hi-item">
                            <Link to="/user/cancelled-bookings">
                                <i className="fas fa-times"></i>
                                Cancelled Bookings
                            </Link>
                        </li>                        
                        <li className="hi-item">
                            <Link to="/user/change-password">
                                <i className="fa fa-lock"></i>
                                Change Password
                            </Link>
                        </li>
                        <li className="hi-item">
                            <Link to="#" onClick={this.handleLogout}>
                                <i className="fas fa-door-open"></i>
                                Logout
                            </Link>
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) =>{
    return {
        ...state
    }
}

export default connect(mapStateToProps, null)(UserDashboardSidebar);