import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

export const firebaseConfig = {
  apiKey: "AIzaSyCJeLvTO9_ZjnYpm9fSYFW2tVzQ0BxPNk0",
  authDomain: "sharearide-digital.firebaseapp.com",
  databaseURL: "https://sharearide-digital-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "sharearide-digital",
  storageBucket: "sharearide-digital.appspot.com",
  messagingSenderId: "1043997755655",
  appId: "1:1043997755655:web:7d21a6eff198e292a85da2",
  measurementId: "G-NWFTL1BB2F"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
export const firebaseDatabase = getDatabase(app);