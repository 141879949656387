import React from "react";
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Table } from "reactstrap";
import { ENDPOINT } from "../constants";
import { connect } from "react-redux";
import alertify from "alertifyjs";
import axios from "axios";
import { recentBookingData } from "../actions/bookingActions";
import moment from 'moment';
import { countryList } from '../utils/CountryCodes.js';
import axiosConfig from "../config/axiosConfig";
import { formatRequest } from "../sagas/common";
import { firebaseDatabase } from "../config/firebaseConfig";
import { ref, update} from "firebase/database";

const { REACT_APP_WEB_URL,  REACT_APP_DPO_GROUP_MERCHANT_TOKEN, REACT_APP_DPO_GROUP_SERVICE_TYPE, REACT_APP_ENCRYPTION_KEY } = process.env || {};
const keyStr = REACT_APP_ENCRYPTION_KEY;

class Booking extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            fare: this.props.fare,
            selected_option: '',
            is_paid: false,
            processing: false,
            paymentURL: '',
            bookingId: '',
            confirmPaymentModal: false,
            noticeModal: false,

            tokenData: null,
            returnPARAMSData: [],
            companyToken: "",
            serviceType: "",
            referenceText: ""
        };
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    handleMethodChange = (e) =>{
        // if(e.target.value === 'offline'){
            this.setState({
                selected_option: 'offline',
                confirmPaymentModal: true
            });         
        // }else if(e.target.value === 'paynow'){
        //     this.setState({
        //         selected_option: 'paynow'
        //     });
        // }
    }

    toggle = async () =>{
        await this.setState({
            noticeModal: !this.state.noticeModal,
            processing: false
        });
        
        if(!this.state.noticeModal){
            this.props.history.push("/user/thank-you");
        }
    }

    updateBookedSeat(seatNumber, payload) {
        const timestamp = moment(this.props.global?.search_date).format("YYYY-MM-DD").valueOf()
        const reference = "bookings/".concat(timestamp).concat("/").concat(this.props.global?.selectedBusDetails?.bus?.uuid).concat("/").concat(seatNumber);
        try{
          update(ref(firebaseDatabase, reference), payload);
        }catch(_e){}  
      }

    handlePayLaterBooking = async (config) =>{
        try{
            const passengers = JSON.parse(config.data)?.passengers
            const response = await axios(config);
            const { success } = response?.data;
            if(success){
                for(let i = 0; i < passengers.length; i++){
                    const payload = {
                        status: "BOOKED",
                    }
                    this.updateBookedSeat(passengers[i]?.seatNumber, payload)
                }
                await this.setState({
                    noticeModal: true
                });
            } else{
                alertify.error("Internal error occurred and the booking was not processed");
            }
        } catch(_err){
            alertify.error("Internal error occurred and the booking was not processed");
        } finally{
            this.setState({
                processing: false,
                selected_option: ''
            });
        }
    }

    handleBooking = async () =>{
        await this.setState({
            processing: true,
            confirmPaymentModal: false            
        });
        const data = JSON.stringify({
            primaryUser: {},
            bookings: {
              id: null,
              uuid: null,
              bookingDate: this.props.global?.search_date,
              ticketPrice: this.props.global?.fare,
              fare: this.props.global?.base_fare,
              seats: "'" + this.props.global?.seats + "'",
              departureSequenceNumber: this.props.global?.selectedBusDetails?.routeDetails?.busSubRoute?.subRoute?.departureSequenceNumber,
              arrivalSequenceNumber: this.props.global?.selectedBusDetails?.routeDetails?.busSubRoute?.subRoute?.arrivalSequenceNumber,
              isPaid: "No",
              cancelledAt: null,
              status: "active",
              user: this.props.Auth?.user.userId,
              busId: this.props.global?.selectedBusDetails?.bus?.uuid,
              busSubRouteId: this.props.global?.selectedBusDetails?.routeDetails?.busSubRoute?.busSubRoute?.uuid
            },
            passengers: this.props.global?.booking_passengers
        });
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.Auth?.token,
        };

        // if(this.state.selected_option === 'offline'){
            const config = {
                headers: headers,
                dataType: "json",
                method: 'post',
                url: `${ENDPOINT}/app/v1/transaction/bookings/`,
                data : data
            };
            this.handlePayLaterBooking(config);
        // } else{
        //     const config = {
        //         headers: headers,
        //         dataType: "json",
        //         method: 'post',
        //         url: `${ENDPOINT}/app/v1/payment/dpogroup/pay/`,
        //         data : data
        //     };
        //     this.handleDpoGroupPay(config);
        // }
    }

    handleWindowClose = async(bookingId) => {
        const config = {
            dataType: "json",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.props.Auth?.token,
            },
        };

        await axios.get(`${ENDPOINT}/app/v1/transaction/bookings/${bookingId}`, config).then(async (resp) => {
            if(resp.status === 200){
                let _booking = resp.data;
                this.props.recentBookingData(_booking);
                if(_booking.isPaid === "Yes"){
                    await this.setState({
                        processing: false
                    });
                    this.props.history.push("/user/thank-you");
                } else{
                    await this.setState({
                        noticeModal: true
                    }); 
                }
            }else{
                await this.setState({
                    processing: false
                });
                alertify.notify("Booking processed, but encountered partial error", 'custom', 2, function(){});
            }
            /**Clear booking sessions */
            this.props.clearAllSearchRelatedHistory();
        });

                   
    }

    handleDpoGroupPay = async (config) => {
        try{
            const passengers = JSON.parse(config.data)?.passengers
            const response = await axios(config);
            const { data, success } = response?.data;
            if(success){
                window.localStorage.setItem(
                    "ACTIVE_BOOKING",
                    JSON.stringify({
                        bookingId: data?.bookingId,
                        userId: this.props.Auth?.user?.userId,
                    })
                );
                for(let i = 0; i < passengers.length; i++){
                    const payload = {
                        status: "BOOKED",
                    }
                    this.updateBookedSeat(passengers[i]?.seatNumber, payload)
                }
                await this.processSubscription(data?.bookingId);                
            } else{
                alertify.error("Internal server error occurred and the booking was not processed");
            }
        } catch(_err){
            alertify.error("Application error occurred and the booking was not processed");
        } finally{
            /**Clear booking sessions */
            this.props.clearAllSearchRelatedHistory();
            this.setState({
                processing: false,
                selected_option: ''
            });
        }
    }

    processSubscription = async(bookingId) =>{
        try{
            const paymentInformation = {
                paymentAmount: this.props.global?.fare,
                paymentCurrency: "BWP",
                customerFirstName: this.props.Auth?.user?.firstName,
                customerLastName: this.props.Auth?.user?.lastName,
                customerAddress: "",
                customerCountry: "Botswana",
                customerCity: "",
                customerEmail: this.props.Auth?.user?.email === (null || "") ? "" : this.props.Auth?.user?.email,
                customerPhone: this.props.Auth?.user?.phone === (null || "") ? "" : this.props.Auth?.user?.phone,
                redirectURL: `${REACT_APP_WEB_URL}/payment/dpogroup/webhooks`,
                backURL: `${REACT_APP_WEB_URL}/payment/dpogroup/webhooks`,
                userId: this.props.Auth?.user?.userId
            }

            this.setState({
                tokenData: paymentInformation,
                companyToken: REACT_APP_DPO_GROUP_MERCHANT_TOKEN,
                serviceType: REACT_APP_DPO_GROUP_SERVICE_TYPE,
                referenceText: bookingId
            });

            const tokens = await this.createToken(bookingId);
            if(tokens && tokens?.transToken && tokens?.transToken !== ""){
                /**Launch DPO payment page */
                window.open(`https://secure.3gdirectpay.com/payv2.php?ID=${tokens?.transToken}`, "_self", "toolbar=yes,scrollbars=yes,resizable=no,top=300,left=500,width=768,height=600");
            }else{
                /**Error occurred generating transaction token */
                alertify.info("Booking payment failed, please try again. If the problem persist after 5 minimal attempts please reach us through our communication channel");
                await this.setState({
                    noticeModal: true
                });
            }
        } catch(_err){
            alertify.error("Payment processing failed but your booking was processed");
            this.setState({
                processing: false,
                selected_option: ''
            });
        }
    }

    addQueryParam = (url, params ) =>{
        const urlInfo = url.split("\\?");
        let queryURL = urlInfo[0];
        let querySTR = urlInfo.length > 1 ? (urlInfo[1] !== (null && "") ? urlInfo[1] : "" ): "";
        let paramsExist = urlInfo.length > 1 && (urlInfo[1] !== (null && ""));
        if ( params.length > 0) {
            const amp = querySTR !== (null && "") ? "?" : "";
            querySTR = querySTR.concat(amp).concat(params);
        }

        if (queryURL !== (null && "") && !paramsExist) {
            return queryURL.concat(querySTR);
        } else if(queryURL !== (null && "")){
            return queryURL.concat("&").concat(querySTR);
        }

        return url;
    }
    returnURL =(url) =>{
        let paramArgs = "dop_params=";
        paramArgs.concat(this.state.returnPARAMSData.toString()).concat("&hash=").concat(this.encode64(this.state.returnPARAMSData.toString()));
        const encryptedParamArgs = this.encode64(paramArgs);
        const param = "dpo_group_return=" + encryptedParamArgs;
        return this.addQueryParam(url, param);
    }
    buildCallbackURL = (paramURL ) =>{
        const redirectURL = this.returnURL( paramURL?.redirectURL);
        return {
            backURL: redirectURL,
            redirectURL: redirectURL
        }
    }
    paymentParams =(bookingId) =>{
        let paramArgs = {
            paymentAmount: 0,
            paymentCurrency: "BWP",
            customerFirstName: this.state.tokenData?.customerFirstName,
            customerLastName: this.state.tokenData?.customerLastName,
            customerAddress: this.state.tokenData?.customerAddress,
            customerCity: this.state.tokenData?.customerCity,
            customerCountry: "BW",
            customerEmail: this.state.tokenData?.customerEmail,
            customerPhone: this.state.tokenData?.customerPhone
        }

        if(this.state.tokenData?.userId !== (null && "")){
            const amountObject = {
                amount: this.state.tokenData?.paymentAmount,
                userId: this.state.tokenData?.userId,
                bookingId: bookingId
            }
            this.setState({
                returnPARAMSData: amountObject
            })

            if(this.state.tokenData?.paymentAmount !== (null && "")){
                paramArgs = {
                    ...paramArgs,
                    paymentAmount: this.state.tokenData?.paymentAmount
                }
            }

            if(this.state.tokenData?.customerCountry !== (null && "")){
                const countryCodes = countryList;
                let countryDetails = countryCodes.filter(country => country.countryInfo.country === this.state.tokenData?.customerCountry);
                if(!countryDetails[0]?.code !== (null || "")){
                    paramArgs ={
                        ...paramArgs,
                        customerCountry: countryDetails[0]?.code
                    }
                } else{
                    countryDetails = countryCodes.filter( country => country.code === this.state.tokenData?.customerCountry);
                    if(countryDetails?.code !== (null || "")){
                        paramArgs ={
                            ...paramArgs,
                            customerCountry: countryDetails?.code
                        }
                    }
                }
            }

            if(this.state.tokenData?.customerPhone !== (null && "")){
                paramArgs = {
                    ...paramArgs,
                    customerPhone: this.state.tokenData?.customerPhone?.replace("+", "").replace("-", "").replace("(", "").replace(")", "")
                }
            }
        }

        paramArgs = {
            ...paramArgs,
            companyToken: this.state.companyToken,
            serviceType: this.state.serviceType,
            serviceDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            referenceText: this.state.referenceText,
            description: "Sharearide booking service"
        }

        const paramURL = {
            backURL: this.state.tokenData?.backURL,
            redirectURL: this.state.tokenData?.redirectURL
        }

        const buildCallBackUrl = this.buildCallbackURL(paramURL);
        paramArgs = {
            ...paramArgs,
            backURL: buildCallBackUrl?.backURL,
            redirectURL: buildCallBackUrl?.redirectURL
        }
        return paramArgs;
    }
    executeDpoRequest = async(postXml) =>{
        return axiosConfig(
            formatRequest(
              `/api/custom/payment/dpo/initiate`,
              "POST",
              { payload: postXml },
              true,
              true,
              1000 * 60 * 5
            )
        );
    }
    createToken = async(bookingId) =>{
        const paymentArgs = this.paymentParams(bookingId);
        const postXml = `<?xml version='1.0' encoding='utf-8'?>
            <API3G>
                <CompanyToken>${this.state.companyToken}</CompanyToken>
                <Request>createToken</Request>
                <Transaction>
                    <PaymentAmount>${paymentArgs?.paymentAmount}</PaymentAmount>
                    <PaymentCurrency>${paymentArgs?.paymentCurrency}</PaymentCurrency>
                    <CompanyRef>${paymentArgs?.referenceText || ""}</CompanyRef>
                    <customerFirstName>${paymentArgs?.customerFirstName || ""}</customerFirstName>
                    <customerLastName>${paymentArgs?.customerLastName || ""}</customerLastName>
                    <customerAddress>${paymentArgs?.customerAddress || ""}</customerAddress>
                    <customerCity>${paymentArgs?.customerCity || ""}</customerCity>
                    <customerCountry>${paymentArgs?.customerCountry || ""}</customerCountry>
                    <customerPhone>${paymentArgs?.customerPhone || ""}</customerPhone>
                    <customerEmail>${paymentArgs?.customerEmail || ""}</customerEmail>
                    <CompanyRefUnique>0</CompanyRefUnique>
                    <PTL>5</PTL>
                    <RedirectURL>${paymentArgs?.redirectURL}</RedirectURL>
                    <BackURL>${paymentArgs?.backURL}</BackURL>
                </Transaction>
                <Services>
                    <Service>
                        <ServiceType>${this.state.serviceType}</ServiceType>
                        <ServiceDescription>${paymentArgs?.description}</ServiceDescription>
                        <ServiceDate>${paymentArgs?.serviceDate}</ServiceDate>
                    </Service>
                </Services>
            </API3G>`;
        let returnResult = {};
        try{
            /** Generate token */            
            const apiResponse = await this.executeDpoRequest(postXml);
            const { data } = apiResponse.data
            let response = data;
            response = response?.API3G !== (null && "") ? response?.API3G : null;
            if(response?.Result !== (null && "")){
                if(response?.Result !== "000"){
                    returnResult ={
                        ...returnResult,
                        success: "FAIL",
                        error: response?.Result
                    }
                } else{
                    returnResult ={
                        ...returnResult,
                        success: "OK",
                        error: response?.Result,
                        transToken: response?.TransToken,
                        resultExplanation: response?.ResultExplanation,
                        transRef: response?.TransRef
                    }
                }
                return returnResult;
            }
        } catch(_e){
            returnResult ={
                ...returnResult,
                success: "FAIL",
                error: "Application failed with an unexpected error"
            }
            return returnResult;
        }
    }

    encode64(input) {
        input = escape(input);
        let output = "";
        let chr1, chr2, chr3 = "";
        let enc1, enc2, enc3, enc4 = "";
        let i = 0;
        do {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output = output +
            keyStr.charAt(enc1) +
            keyStr.charAt(enc2) +
            keyStr.charAt(enc3) +
            keyStr.charAt(enc4);
            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);	 
        return output;
    }
    decode64(input) {
        let output = "";
        let chr1, chr2, chr3 = "";
        let enc1, enc2, enc3, enc4 = "";
        let i = 0;
        // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
        const base64test = /[^A-Za-z0-9\+\/\=]/g;
        if (base64test.exec(input)) {
        	alert("There were invalid base64 characters in the input text.\n" +
        	    "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
        	    "Expect errors in decoding."
            );
        }
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        do {
        	enc1 = keyStr.indexOf(input.charAt(i++));
        	enc2 = keyStr.indexOf(input.charAt(i++));
        	enc3 = keyStr.indexOf(input.charAt(i++));
        	enc4 = keyStr.indexOf(input.charAt(i++));
        	chr1 = (enc1 << 2) | (enc2 >> 4);
        	chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        	chr3 = ((enc3 & 3) << 6) | enc4;
        	output = output + String.fromCharCode(chr1);
        	if (enc3 !== 64) {
        	   output = output + String.fromCharCode(chr2);
        	}
        	if (enc4 !== 64) {
        	    output = output + String.fromCharCode(chr3);
        	}
        	chr1 = chr2 = chr3 = "";
        	enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);
        return unescape(output);
    } 

    render() {
        return (
            <>
                <Modal isOpen={this.state.confirmPaymentModal} toggle={()=>{
                    this.setState({
                        confirmPaymentModal: false,
                        selected_option: ''
                    })
                }} className=''>
                    <ModalHeader toggle={()=>{
                        this.setState({
                            confirmPaymentModal: false,
                            selected_option: ''
                        })
                    }}>Attention</ModalHeader>
                    <ModalBody>
                        You are about to make reservation with Sharearide. Please ensure that all details entered are correct and upto your satisfaction. There are penalties for booking cancellation.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={async ()=>{
                            await this.handleBooking();
                        }}>Continue</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.noticeModal} toggle={this.toggle} className=''>
                    <ModalHeader toggle={this.toggle}>Notice</ModalHeader>
                    <ModalBody>
                        Please confirm your booking by paying the pending amount at the terminal atleast 30 minutes before the departure. Otherwise, booking will be cancelled!
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggle}>OK</Button>{' '}
                    </ModalFooter>
                </Modal>
                <section id="content" className="page-single bg-grey">
                    <div id="content-wrap">
                        <div className="section-flat">
                            <div className="section-content">
                                <Container>
                                    <Row md={12}>
                                        <div className="content-main" style={{width: '100%'}}>
                                            <div className="block-content-2 style-3">
                                                <div className="block-title wide-bottom">
                                                    <h3>Booking Summary</h3>
                                                </div>
                                                {!this.state.processing &&<div className="car-title">
                                                    <Row>
                                                        <Col md={1}>
                                                            {
                                                                this.props.global?.selectedBusDetails?.bus?.image ? <img src={new Buffer.from(this.props.global?.selectedBusDetails?.bus?.image).toString("ascii")} alt="" />:
                                                                <img src={require("../assets/images/files/bus.png")} alt="" />
                                                            }
                                                        </Col>
                                                        <Col md={11}>
                                                            <div className="content">
                                                                <h5 className="title">
                                                                    {this.props.global?.selectedBusDetails?.owner?.name}
                                                                </h5>
                                                                <p>
                                                                    <span>Reg # {this.props.global?.selectedBusDetails?.bus?.regNo}</span>
                                                                </p>                                      
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{marginTop: '20px'}}>
                                                        <Col xs={12} md={3}>
                                                            <ul className="list-booking-details">
                                                                <li>
                                                                    <strong>Departure</strong>
                                                                    <span>{this.props.global?.search_from?.label}</span>
                                                                    <span>{this.props.global?.selectedBusDetails?.routeDetails?.busRoute?.departure}</span>
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                        <Col xs={12} md={3}>
                                                            <ul className="list-booking-details">
                                                                <li>
                                                                    <strong>Arrival</strong>
                                                                    <span>{this.props.global?.search_to?.label}</span>
                                                                    <span>{this.props.global?.selectedBusDetails?.routeDetails?.busRoute?.arrival}</span>
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                        <Col xs={12} md={3}>
                                                            <ul className="list-booking-details">
                                                                <li>
                                                                    <strong>Fare Per Seat</strong>
                                                                    <span>BWP {this.props.global?.selectedBusDetails?.routeDetails?.busSubRoute?.busSubRoute?.fare}</span>
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                        <Col xs={12} md={8}>
                                                            <ul className="list-booking-details">
                                                                <li>
                                                                    <strong>Selected Seats</strong>
                                                                    <Table bordered striped>
                                                                        <thead>
                                                                            <tr>
                                                                                <th> Names </th>
                                                                                <th> Seat # </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {(this.props?.global?.booking_passengers || []).map(passenger => {
                                                                                return <tr>
                                                                                    <td scope="row"> {passenger?.names} </td>
                                                                                    <td> {passenger?.seatNumber} </td>
                                                                                </tr>
                                                                            })}
                                                                        </tbody>
                                                                    </Table>
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                        <Col md={12}>
                                                            <div className="block-title wide-bottom" style={{marginBottom: '10px', paddingBottom: '12px'}}>
                                                                <Row>
                                                                    <Col md={4}>
                                                                        <h3 style={{marginTop: '72px'}}>
                                                                            Subtotal: <span className="colored">BWP {this.props.global?.fare}</span>
                                                                        </h3>
                                                                    </Col>
                                                                    <Col md={4}/>
                                                                </Row>
                                                            </div>
                                                            <div className="booking-cost">
                                                                <Row>
                                                                    <Col md={4}>
                                                                        <Input type="submit" className="form-control btn" onClick={this.handleMethodChange} value="Complete Booking" />
                                                                        {/* <div className="btn-setting">
                                                                            <div className="btn-setting-1">
                                                                                <input type="radio" value="offline" style={{color: '#02928b'}} onChange={this.handleMethodChange} checked={this.state.selected_option === 'offline'} name="method" /> Pay Offline
                                                                            </div>
                                                                            <div className="btn-setting-2">
                                                                                <input type="radio" value="paynow" style={{color: '#02928b'}} onChange={this.handleMethodChange} checked={this.state.selected_option === 'paynow'} name="method" /> Pay Now
                                                                            </div>
                                                                        </div> */}
                                                                    </Col>
                                                                    <Col md={6}></Col>
                                                                    <Col md={2}>
                                                                        {this.state.selected_option === 'paynow' && <div>
                                                                            <Button onClick={async () => {
                                                                                await this.setState({
                                                                                    confirmPaymentModal: true
                                                                                });
                                                                            }} style={{ background:'#fff', border:'1px solid #1174bc', padding:'5px 10px', boxShadow: "1px 1px 5px 1px #1174bc"}}>
                                                                                <img src={require("../assets/images/files/payment-getways/dpo.png")} alt="DPO GROUP"/>
                                                                            </Button>
                                                                        </div>}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>}
                                                {this.state.processing && <div className="row">
                                                    <Col md={12}>
                                                        <div className="content-main" id="bus-listing">
                                                            <div class="ring" style={{ marginTop: 0, padding: 0, background: "#262626", fontSize: "10px" }}>Processing... <span class="span"></span></div>
                                                        </div>
                                                    </Col>
                                                </div>}
                                            </div>
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state,) =>{
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        recentBookingData: (data) =>{
            dispatch(recentBookingData(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Booking);