import React from "react";
import { Container, Row, Col } from "reactstrap";
import { ENDPOINT } from "../constants";
import { connect } from "react-redux";
import alertify from "alertifyjs";
import axios from "axios";
import { Table } from 'reactstrap';
import UserSidebar from "./UserDashboardSidebar";
import { persister } from "../redux/store";

class Wallet extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            withdrawals: [],
        };
    }

    async componentDidMount(){
        window.scrollTo(0, 0);

        const config = {
            dataType: "json",
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer '+this.props.Auth?.token,
            },
        };
      
        return await axios.get(ENDPOINT+'/get-withdrawals',config)
        .then(response => {
            
            if(response.data.code === 200){
                this.setState({withdrawals: response.data.withdrawals})
            }
            if(response.data.code === 404){
                alertify.notify(response.data.message, 'custom', 2, function(){});
                return null;
            }
            
        })
        .catch((error) => {
            if(error.message === 'Request failed with status code 401'){
                persister.purge();
            }
        });
    }

    render() {
        var num = 0;
        var listItems = this.state.withdrawals.map(function(withdraw, key) {
            
            num++;
            return (
                <tr key={key}>
                    <td>{num}</td>
                    <td>{withdraw.account_number}</td>
                    <td>{withdraw.bank_name}</td>
                    <td>{withdraw.branch_code}</td>
                    <td>{withdraw.branch_address}</td>
                    <td>{withdraw.iban_number}</td>
                    <td>{withdraw.amount}</td>
                </tr>                
            );
          }, this);
      
        return (
          <>
            <section id="content" className="page-single bg-grey">
                <div id="content-wrap">
                    <div className="section-flat">
                        <div className="section-content">
                            <Container>
                                <Row>
                                    <Col lg={3} md={12} className="mb-4">
                                        <UserSidebar />
                                    </Col >
                                    <Col md={9} >
                                        <div className="content-main" style={{width: '100%'}}>
                                            <div className="block-content-2 style-3">
                                                <div className="block-title wide-bottom">
                                                    <h3>withdrawals</h3>
                                                    
                                                </div>
                                                <Table className="row-design">
                                                    <thead>
                                                        <tr>
                                                        <th>#</th>
                                                        <th>Account Number</th>
                                                        <th>Bank Name</th>
                                                        <th>Branch Code</th>
                                                        <th>Branch Address</th>
                                                        <th>IBAN Number</th>
                                                        <th>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {listItems}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>

                                        {/* <div className="content-main" style={{width: '100%'}}>
                                            {listItems}
                                        </div> */}
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </section>
          </>
        );
    }
}

const mapStateToProps = (state) =>{
    return {
        ...state
    }
}

export default connect(mapStateToProps, null)(Wallet);